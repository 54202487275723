<script lang="ts" setup>
const slideItem = [
    {
        slide: '/split-system.webp',
        title: 'Сплит система',
        description: 'Установка сплит системы',
    },
    {
        slide: '/multi-split-system.webp',
        title: 'Мульти-сплит система',
        description: 'Установка мульти-сплит системы',
    },
    {
        slide: '/kolon.webp',
        title: 'Колонные кондиционеры',
        description: 'Установка колонных кондиционеров',
    },
    {
        slide: '/kanal.webp',
        title: 'Канальные кондиционеры',
        description: 'Установка приточной вентиляции',
    },
    {
        slide: '/kaset.webp',
        title: 'Кассетные кондиционеры',
        description: 'Установка кассетных кондиционеров',
    },
    {
        slide: '/napol-potolok.webp',
        title: 'Напольно-потолочные кондиционеры',
        description: 'Установка напольно-потолочных кондиционеров',
    },
    {
        slide: '/portfolio-block-slide-active.webp',
        title: 'Приточная Вентиляция',
        description: 'Установка приточной вентиляции',
    },
    {
        slide: '/portfolio-block-slide1.webp',
        title: 'Вытяжная вентиляция',
        description: 'Установка вытяжной вентиляции',
    },
    {
        slide: '/portfolio-block-slide2.webp',
        title: 'Приточно-вытяжная вентиляция',
        description: 'Установка приточно-вытяжной вентиляции',
    },
];
</script>

<template>
    <section
        id="portfolio"
        class="portfolio-medium"
    >
        <div class="portfolio-medium__section-wrapper">
            <div class="portfolio-medium__section">
                <h6 class="portfolio-medium__title">
                    Портфолио И Процесс работы проектирования и установки
                    кондиционеров и вентиляции
                </h6>
                <div class="portfolio-medium__description">
                    Технические решения, принятые в рабочих чертежах при
                    проектировании и установке кондиционеров и вентиляции
                    соответствуют требованиям экологических,
                    санитарно-гигиенических, противопожарных и других норм и
                    правил, и обеспечивают безопасную для жизни людей
                    эксплуатацию объекта при соблюдении предусмотренных рабочими
                    чертежами мероприятий.
                </div>
                <div class="portfolio-medium__content">
                    <div
                        v-for="slide in slideItem"
                        :key="slide.title"
                        class="portfolio-medium__img-wrapper"
                    >
                        <NuxtImg
                            :src="slide.slide"
                            :alt="slide.title"
                            :title="slide.description"
                            class="portfolio-medium__img"
                            width="221"
                            height="221"
                            loading="lazy"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="less">
.portfolio-medium {
    position: relative;
    background-color: #ffffff;
    padding-bottom: 75px;

    &__section {
        width: 944px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        &-wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }

    &__title {
        font-family: 'Ruberoid';
        font-size: 40px;
        font-weight: 500;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(73, 94, 76, 1);
    }

    &__description {
        font-family: 'Proxima Nova Rg';
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(132, 132, 132, 1);
        margin-bottom: 30px;
    }

    &__content {
        width: 944px;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: min-content;
        font-family: 'Proxima Nova Rg';
    }

    &__img {

        &-wrapper {
            width: 221px;
            height: 221px;
        }
    }
}
</style>
