<script setup>
const solutionItem = [
    {
        image: '/solution-item-house.webp',
        description: 'Кондиционеры и вентиляция в Квартиры и офисы',
        title: 'Купить кондиционер в квартиру или офис',
    },
    {
        image: '/solution-item-tc.webp',
        description: 'Кондиционеры и вентиляция в Магазины и ТЦ',
        title: 'Купить кондиционер в магазин или ТЦ',
    },
    {
        image: '/solution-item-fitnes.webp',
        description: 'Кондиционеры и вентиляция в Фитнес центры',
        title: 'Купить кондиционер фитнес центр',
    },
    {
        image: '/solution-item-baseyn.webp',
        description: 'Кондиционеры и вентиляция в Бассейны',
        title: 'Купить кондиционер в бассейн',
    },
    {
        image: '/solution-item-house2.webp',
        description:
            'Кондиционеры и вентиляция в Загородные дома, коттеджи, таунхаусы',
        title: 'Купить кондиционер в загородный дом, коттедж или таунхаус',
    },
    {
        image: '/solution-item-bar.webp',
        description: 'Кондиционеры и вентиляция в Рестораны, кафе, бары',
        title: 'Купить кондиционер в ресторан, кафе или бар',
    },
    {
        image: '/solution-item-clinic.webp',
        description: 'Кондиционеры и вентиляция в Стоматологии, клиники',
        title: 'Купить кондиционер в стоматологию или клинику',
    },
    {
        image: '/solution-item-spa.webp',
        description: 'Кондиционеры и вентиляция в Салоны красоты, СПА',
        title: 'Купить кондиционер в салон красоты или СПА',
    },
];
</script>

<template>
    <section
        id="solution"
        class="solution-medium"
    >
        <div class="solution-medium__content-wrapper">
            <div class="solution-medium__content">
                <h4 class="solution-medium__title">
                    Комплексное Решение По Кондиционированию И Вентиляции
                    Под Ключ
                </h4>
                <div class="solution-medium__line" />
                <div class="solution-medium__item-wrapper">
                    <SolutionItemMedium
                        v-for="item in solutionItem"
                        :key="item.description"
                        class="solution-medium__item"
                        :image="item.image"
                        :description="item.description"
                        :title="item.title"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="less">
.solution-medium {
    height: 800px;
    position: relative;
    background:
        left/288px 488px no-repeat url('~/assets/img/background-solution1.svg'),
        left 304px bottom 237px / 40px 20px no-repeat
            url('~/assets/img/background-solution4.webp'),
        left 397px bottom 212px / 24px 34px no-repeat
            url('~/assets/img/background-solution5.webp'),
        left 360px bottom 77px / 65px 70px no-repeat
            url('~/assets/img/background-solution7.webp'),
        left 321px bottom 425px / 1026px 220px no-repeat
            url('~/assets/img/background-solution2.webp'),
        right 0 bottom 58px / 1000px 220px no-repeat
            url('~/assets/img/background-solution3.webp'),
        left 1318px top 217px / 53px 74px no-repeat
            url('~/assets/img/background-solution6.webp'),
        left 1395px top 216px / 40px 20px no-repeat
            url('~/assets/img/background-solution8.webp'),
        #ffffff;

    &__content {
        width: 944px;
        display: flex;
        flex-direction: column;
        position: relative;

        &-wrapper {
            width: 100%;
            height: 800px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }
    }

    &__title {
        font-family: 'Ruberoid';
        font-size: 40px;
        font-weight: 500;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(73, 94, 76, 1);
        position: relative;
        margin-bottom: 40px;
    }

    &__line {
        width: 460px;
        height: 3px;
        background-color: rgba(120, 192, 129, 1);
        position: absolute;
        top: 152px;
        left: 477px;
        border-radius: 1.5px;
    }

    &__item {
        &-wrapper {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: repeat(3, 0.69fr);
            grid-column-gap: 25px;
            grid-row-gap: 15px;
            justify-items: center;
        }
    }
}
</style>
