<script setup>
const contactsItem = [
    {
        icon: '/icon-phone.svg',
        alt: 'Телефон компании Air Arts',
        description:
            '<a href="tel:+74951430202" aria-label="Телефон">+7&nbsp;(495)&nbsp;143&#8209;02&#8209;02</a>',
    },
    {
        icon: '/footer-block-mail.svg',
        alt: 'Email компании Air Arts',
        description:
            '<a href="mailto:info@air-arts.ru" aria-label="Email">email: info@air-arts.ru</a>',
    },
    {
        icon: '/vk.svg',
        alt: 'VK компании Air Arts',
        description:
            '<a href="//vk.com/air__arts" target="_blank" rel="noopener noreferrer" aria-label="VK">Air Arts в VK</a>',
    },
    {
        icon: '/telegram.svg',
        alt: 'Telegram компании Air Arts',
        description:
            '<a href="//t.me/air_arts" target="_blank" rel="noopener noreferrer" aria-label="Telegram">Air Arts в Telegram</a>',
    },
    {
        icon: '/footer-block-calendar.svg',
        alt: 'Режим работы компании Air Arts',
        description: 'Пн - Пт: 10:00 - 18:00<br>Сб, Вс: выходной',
    },
    {
        icon: '/icon-place.svg',
        alt: 'Адрес компании Air Arts',
        description:
            '<a href="//yandex.ru/maps/10765/shelkovo/?ll=37.991528%2C55.919461&mode=routes&rtext=~55.877350%2C38.087820&rtt=auto&ruri=~&z=12.98" aria-label="Адрес" target="_blank" rel="noopener noreferrer">г. Щёлково, ул. Беляева 7а, Московская область</a>',
    },
];
</script>

<template>
    <div class="contacts-medium">
        <h2 class="contacts-medium__title">
            Контакты
        </h2>
        <div
            v-for="(item, index) in contactsItem"
            :key="item.icon"
            class="contacts-medium__item"
        >
            <NuxtImg
                :src="item.icon"
                :alt="item.alt"
                :title="`Изображение ${item.alt}`"
                class="contacts-medium__icon"
                :width="index === 0 ? 22 : index === 1 ? 22 : index === 2 ? 24 : index === 3 ? 24 : index === 4 ? 22 : 22"
                :height="index === 0 ? 22 : index === 1 ? 22 : index === 2 ? 24 : index === 3 ? 24 : index === 4 ? 22 : 28"
                loading="lazy"
            />
            <div
                class="contacts-medium__description"
                v-html="item.description"
            />
        </div>
        <a
            href="//www.liveinternet.ru/click"
            class="liveinternet"
            target="_blank"
        >Число поситителей Air Arts
            <img
                id="licntA831"
                width="88"
                height="15"
                style="border: 0"
                title="LiveInternet: показано число посетителей за сегодня"
                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAIBTAA7"
                alt="LiveInternet: показано число посетителей за сегодня"
            >
        </a>
    </div>
</template>

<style lang="less">
.contacts-medium {
    width: 190px;
    margin-top: 80px;

    &__title {
        font-family: 'Ruberoid';
        font-size: 26px;
        font-weight: 500;
        line-height: 35px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(73, 94, 76, 1);
        margin-bottom: 20px;
    }

    &__item {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(190, 225, 194, 1);

        &:last-of-type {
            border-bottom: unset;
        }
    }

    &__icon {
        margin-right: 15px;
    }

    &__description {
        font-family: 'Proxima Nova Rg';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(123, 137, 125, 1);
    }

    a {
        color: rgba(123, 137, 125, 1);
        text-decoration: none;
    }

    .liveinternet {
        font-size: 0;
    }
}
</style>
