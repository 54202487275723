import { useState, onMounted, onUnmounted } from '#imports';

export const useUser = () => {
    return useState('user', () => {
        return {
            jwt: null,
            id: null,
            documentId: '',
            username: '',
            email: '',
            provider: '',
            confirmed: null,
            blocked: null,
            createdAt: '',
            updatedAt: '',
            publishedAt: '',
        };
    });
};

export const useScroll = () => {
    const scrollY = useState('scrollY');

    function updateScrollY() {
        scrollY.value = window.scrollY;
    }

    onMounted(() => window.addEventListener('scroll', updateScrollY));
    onUnmounted(() => window.removeEventListener('scroll', updateScrollY));

    return useState('scrollY');
};
