import { defineNuxtPlugin, onNuxtReady } from '#imports';

export default defineNuxtPlugin(() => {
    setTimeout(() => {
        onNuxtReady(async () => {
            await $fetch(
                'https://www.googletagmanager.com/gtag/js?id=G-BWFWKNL8TW',
            );

            // @ts-ignore: Unreachable code error
            window.dataLayer = window.dataLayer || [];
            // @ts-ignore: Unreachable code error
            function gtag(...args) {
                // eslint-disable-next-line no-undef
                dataLayer.push(args);
            }
            // @ts-ignore: Unreachable code error
            gtag('js', new Date());
            // @ts-ignore: Unreachable code error
            gtag('config', 'G-BWFWKNL8TW');

            (function (w, d, s, l, i) {
                // @ts-ignore: Unreachable code error
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start': new Date().getTime(),
                    'event': 'gtm.js',
                });
                var f = d.getElementsByTagName(s)[0],
                    // @ts-ignore: Unreachable code error
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src
                    // @ts-ignore: Unreachable code error
                    = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-NZB887XJ');

            (function (m, e, t, r, i, k, a) {
                // @ts-ignore: Unreachable code error
                m[i]
                    = m[i]
                    || function (...args) {
                        (m[i].a = m[i].a || []).push(args);
                    };
                // @ts-ignore: Unreachable code error
                m[i].l = 1 * new Date();
                for (var j = 0; j < document.scripts.length; j++) {
                    if (document.scripts[j].src === r) {
                        return;
                    }
                }
                // @ts-ignore: Unreachable code error
                (k = e.createElement(t)),
                (a = e.getElementsByTagName(t)[0]),
                (k.async = 1),
                (k.src = r),
                a.parentNode.insertBefore(k, a);
            })(
                window,
                document,
                'script',
                'https://mc.yandex.ru/metrika/tag.js',
                'ym',
            );
            // @ts-ignore: Unreachable code error
            // eslint-disable-next-line no-undef
            ym(96280408, 'init', {
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
            });

            (function (d, s) {
                d.getElementById('licntA831').src
                    = 'https://counter.yadro.ru/hit?t26.15;r'
                    + escape(d.referrer)
                    + (typeof s == 'undefined'
                        ? ''
                        : ';s'
                            + s.width
                            + '*'
                            + s.height
                            + '*'
                            + (s.colorDepth ? s.colorDepth : s.pixelDepth))
                        + ';u'
                        + escape(d.URL)
                        + ';h'
                        + escape(d.title.substring(0, 150))
                        + ';'
                        + Math.random();
            })(document, screen);

            var _tmr = window._tmr || (window._tmr = []);
            _tmr.push({
                id: '3495253',
                type: 'pageView',
                start: new Date().getTime(),
            });
            (function (d, w, id) {
                if (d.getElementById(id)) return;
                var ts = d.createElement('script');
                ts.type = 'text/javascript';
                ts.async = true;
                ts.id = id;
                ts.src = 'https://top-fwz1.mail.ru/js/code.js';
                var f = function () {
                    var s = d.getElementsByTagName('script')[0];
                    s.parentNode.insertBefore(ts, s);
                };
                if (w.opera == '[object Opera]') {
                    d.addEventListener('DOMContentLoaded', f, false);
                }
                else {
                    f();
                }
            })(document, window, 'tmr-code');

            (function (w, d, c) {
                (w[c] = w[c] || []).push(function () {
                    var options = {
                        project: 7728810,
                    };
                    try {
                        // eslint-disable-next-line no-undef
                        w.top100Counter = new top100(options);
                    }
                    catch (e) {
                        console.log(e);
                    }
                });
                var n = d.getElementsByTagName('script')[0],
                    s = d.createElement('script'),
                    f = function () {
                        n.parentNode.insertBefore(s, n);
                    };
                s.type = 'text/javascript';
                s.async = true;
                s.src
                    = (d.location.protocol == 'https:' ? 'https:' : 'http:')
                    + '//st.top100.ru/top100/top100.js';

                if (w.opera == '[object Opera]') {
                    d.addEventListener('DOMContentLoaded', f, false);
                }
                else {
                    f();
                }
            })(window, document, '_top100q');
        });
    }, 5000);
});
