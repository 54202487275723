<script setup>
import { useScroll, ref, useRoute } from '#imports';

const route = useRoute();

const scrollY = useScroll();

const emits = defineEmits(['openPopUpConnection']);

const activeChildren = ref('');
const disableChildren = ref('');
const childrenHidden = ref(true);
const childrenVisible = ref(false);

const navItem = [
    {
        name: 'Главная страница',
        link: '/',
        subLink: [],
    },
    {
        name: 'Услуги кондиционирования',
        link: '/conditioning',
        subLink: [
            {
                name: 'Установка кондиционеров',
                link: '/conditioning/installing-air-conditioner',
                subLink: [],
            },
            {
                name: 'Ремонт кондиционеров',
                link: '/conditioning/repair-air-conditioner',
                subLink: [],
            },
            {
                name: 'Обслуживание кондиционеров',
                link: '/conditioning/air-conditioning-maintenance',
                subLink: [],
            },
        ],
    },
    {
        name: 'Услуги вентиляции',
        link: '/ventilation',
        subLink: [
            {
                name: 'Установка вентиляции',
                link: '/ventilation/installation-ventilation',
                subLink: [],
            },
            {
                name: 'Ремонт вентиляции',
                link: '/ventilation/repair-ventilation',
                subLink: [],
            },
            {
                name: 'Обслуживание вентиляции',
                link: '/ventilation/ventilation-maintenance',
                subLink: [],
            },
        ],
    },
    {
        name: 'Каталог кондиционеров',
        link: '/catalog',
        subLink: [
            {
                name: 'Кондиционеры настенные',
                link: '/catalog/split-system',
                subLink: [],
            },
            {
                name: 'Кондиционеры мульти-сплит',
                link: '/catalog/multi-split-system',
                subLink: [],
            },
            {
                name: 'Кондиционеры кассетные',
                link: '/catalog/kassetnie',
                subLink: [],
            },
            {
                name: 'Кондиционеры напольно-потолочные',
                link: '/catalog/napolno-potolochnie',
                subLink: [],
            },
            {
                name: 'Кондиционеры колонные',
                link: '/catalog/kolonnie',
                subLink: [],
            },
            {
                name: 'Кондиционеры канальные',
                link: '/catalog/kanalnie',
                subLink: [],
            },
        ],
    },
    {
        name: 'Статьи о кондиционировании и вентиляции',
        link: '/articles',
        subLink: [],
    },
    {
        name: 'О Нас',
        link: '/about',
        subLink: [],
    },
    {
        name: 'Контакты компании',
        link: '/contacts',
        subLink: [],
    },
];

const handlerMenuItemEnter = (name) => {
    activeChildren.value = name;
    childrenVisible.value = true;
    childrenHidden.value = false;
};

const handlerMenuItemLeave = () => {
    disableChildren.value = '';
    childrenHidden.value = true;
    childrenVisible.value = false;
};

const closeChildrenMenu = () => {
    childrenVisible.value = false;
    childrenHidden.value = true;
    activeChildren.value = '';
};

const handlerOpenPopUp = () => {
    emits('openPopUpConnection');
};
</script>

<template>
    <header
        id="header"
        class="header-tablet"
    >
        <div class="header-tablet__content-wrapper">
            <div class="header-tablet__content">
                <div class="header-tablet__logo-wrapper">
                    <NuxtLink to="/">
                        <NuxtImg
                            class="header-tablet__logo"
                            src="/logo.svg"
                            alt="Logo Air Arts"
                            title="Логотип Air Arts"
                            width="116"
                            height="75"
                            preload
                        />
                        <div class="header-tablet__logo-text">Air Arts</div>
                        <div class="header-tablet__logo-description">
                            for fresh air
                        </div>
                    </NuxtLink>
                </div>
                <nav class="header-tablet__nav">
                    <ul class="header-tablet__nav-list">
                        <li
                            v-for="item in navItem"
                            :key="item.name"
                            class="header-tablet__nav-list-item"
                        >
                            <div
                                class="header-tablet__nav-list-item-text"
                                :class="{
                                    'header-tablet__nav-list-item-text_active':
                                        route.fullPath === item.link
                                        || route.fullPath
                                            === `${item.link}#header`,
                                    'header-tablet__nav-list-item-text_subitem':
                                        item.subLink.length,
                                }"
                                @mouseenter="handlerMenuItemEnter(item.name)"
                                @mouseleave="handlerMenuItemLeave()"
                            >
                                <NuxtLink
                                    :key="item.link"
                                    :to="item.link"
                                    class="header-tablet__nav-list-item-text"
                                >
                                    {{ item.name }}
                                </NuxtLink>
                                <NuxtImg
                                    v-if="item.subLink.length"
                                    src="/shevron.svg"
                                    alt="Изображение указателя статуса меню"
                                    title="Иконка статуса меню"
                                    width="10"
                                    height="10"
                                    class="header-tablet__nav-list-item-shevron"
                                    preload
                                />
                                <div
                                    v-if="item.subLink.length"
                                    class="header-tablet__nav-list-item-sublink-wrapper header-tablet__nav-list-item-text_active-subitem"
                                >
                                    <ul
                                        v-if="
                                            childrenVisible
                                                && !childrenHidden
                                                && activeChildren === item.name
                                        "
                                        class="header-tablet__nav-list-sublink"
                                    >
                                        <li
                                            v-for="sublink in item.subLink"
                                            :key="sublink.link"
                                            class="header-tablet__nav-list-item"
                                        >
                                            <NuxtLink
                                                :key="sublink.link"
                                                :to="sublink.link"
                                                :class="[
                                                    'header-tablet__nav-list-item-sublink',,
                                                    {
                                                        'header-tablet__nav-list-item-sublink_active':
                                                            route.fullPath
                                                            === sublink.link
                                                            || route.fullPath
                                                                === `${sublink.link}#header`,
                                                    },
                                                ]"
                                                @click="closeChildrenMenu"
                                            >
                                                {{ sublink.name }}
                                            </NuxtLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
                <div class="header-tablet__button-wrapper">
                    <ButtonComponent
                        class="header-tablet__button"
                        text="Связаться"
                        @click="handlerOpenPopUp"
                    />
                    <ClientOnly>
                        <a
                            href="//yandex.ru/profile/97416138360?intent=reviews"
                            target="_blank"
                            class="header-tablet__link-rating"
                            aria-label="Рейтинг компании Air Arts от Яндекс"
                            rel="noopener noreferrer"
                        >Рейтинг компании Air Arts от Яндекс
                            <div class="header-tablet__link-rating-wrapper">
                                <a
                                    class="RatingBadgeWidget"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Рейтинг компании Air Arts от Яндекс"
                                    href="//yandex.ru/maps/org/97416138360/reviews?utm_source=badge&amp;utm_medium=rating&amp;utm_campaign=v1"
                                    style="
                                        background-image: url(https://avatars.mds.yandex.net/get-altay/474904/badge_rating_light_4.8/orig);
                                    "
                                >Рейтинг компании Air Arts от Яндекс
                                </a>
                            </div>
                        </a>
                    </ClientOnly>
                </div>
            </div>
        </div>
    </header>
    <Transition>
        <div
            v-if="scrollY"
            class="header-tablet"
            :class="{ 'header-tablet_active': scrollY }"
        >
            <div class="header-tablet__content-wrapper">
                <div class="header-tablet__content">
                    <NuxtLink to="/">
                        <NuxtImg
                            class="header-tablet__logo"
                            src="/logo.svg"
                            alt="Logo Air Arts"
                            title="Логотип Air Arts"
                            width="116"
                            height="75"
                            loading="lazy"
                        />
                        <div class="header-tablet__logo-text">Air Arts</div>
                        <div class="header-tablet__logo-description">
                            for fresh air
                        </div>
                    </NuxtLink>
                    <ButtonComponent
                        class="header-tablet__button"
                        text="Связаться"
                        @click="handlerOpenPopUp"
                    />
                </div>
            </div>
        </div>
    </Transition>
</template>

<style lang="less">
.header-tablet {
    width: 100%;
    position: relative;
    padding-top: 19px;
    padding-bottom: 20px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    z-index: 1;
    background-color: rgba(217, 250, 221, 0);

    .RatingBadgeWidget {
        display: block;
        width: 150px;
        height: 31.5px;
        background-repeat: no-repeat;
        background-position: top;
        background-size: 100%;
    }

    &__content {
        width: 1000px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__link-rating {
        font-size: 0;
        position: absolute;
        right: 0;
        bottom: -40px;
        width: 150px;
        height: 32px;
        text-decoration: none;
        touch-action: manipulation;
        outline: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;

        &-wrapper {
            position: absolute;
            width: 150px;
            height: 31.5px;
            border-radius: 5px;
            overflow: hidden;
        }
    }

    &_active {
        position: fixed;
        top: 0;
        z-index: 100;
        backdrop-filter: blur(8px);
        background: rgba(217, 250, 221, 0.55);

        .header-tablet__button.button {
            border-radius: 20px;
            background-color: rgba(120, 192, 129, 0.25);
        }
    }

    &__logo {
        cursor: pointer;

        &-wrapper {
            width: 350px;
            height: 75px;
        }

        &-text {
            font-family: 'Ruberoid';
            font-weight: 900;
            font-size: 2rem;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: var(--text);
            position: absolute;
            left: 88px;
            top: 25px;
            cursor: pointer;
        }

        &-description {
            font-family: 'Ruberoid';
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: var(--text2);
            position: absolute;
            left: 88px;
            top: 52px;
            cursor: pointer;
        }
    }

    &__nav {
        display: inline;
        margin: 0 20px;

        &-list {
            height: 75px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin-left: 0;

            &-sublink {
                display: none;
                width: 100%;
                position: absolute;
                top: 33px;
                left: 0;
                -webkit-backdrop-filter: blur(8px);
                backdrop-filter: blur(8px);
                background: rgba(217, 250, 221, 1);
                z-index: 500000000;
                padding: 7px 14px 5px;

                &-wrapper {
                    position: absolute;
                }
            }

            &-item {
                list-style: none;

                &-sublink {
                    color: var(--text);
                    transition: color 0.3s;
                    text-decoration: none;
                    display: block;
                    padding: 5px 14px 10px;

                    &_active {
                        color: var(--text2);
                    }

                    &:hover {
                        color: var(--text2);
                        transition: color 0.3s;
                    }
                }

                &-shevron {
                    padding-top: 5px;
                    transform: rotateX(0);
                    transition: transform 0.3s;
                }

                &-text {
                    padding: 10px 11px 5px;
                    width: 106%;
                    font-family: 'Proxima Nova Rg';
                    font-size: 1.5rem;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;
                    text-transform: lowercase;
                    cursor: pointer;
                    position: relative;
                    background-color: transparent;
                    color: var(--text);
                    transition: color 0.3s;
                    text-decoration: none;

                    &_active-subitem {
                        .header-tablet__nav-list-sublink {
                            display: block;
                        }
                    }

                    &:hover {
                        color: var(--text2);
                        transition: color 0.3s;
                    }

                    &_active {
                        .header-tablet__nav-list-item-text {
                            color: var(--text2);
                        }
                    }

                    &_subitem {
                        &:hover {
                            -webkit-backdrop-filter: blur(8px);
                            backdrop-filter: blur(8px);
                            background-color: rgba(217, 250, 221, 1);
                            transition: color 0.3s;
                            z-index: 500000000;

                            .header-tablet__nav-list-item-shevron {
                                transform: rotateX(180deg) translateY(-3px);
                                transition: transform 0.3s;
                            }
                        }
                    }
                }
            }
        }
    }

    &__button {
        width: 210px;
        min-width: 210px;
        height: 75px;
        border-radius: 17px;
        box-shadow: 2px -2px 12px 0px rgba(34, 111, 33, 0.36) inset;
    }
}

.v-enter-active,
.v-leave-active {
    opacity: 1;
    transition: opacity 0.3s ease 0.1s;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
    transition: opacity 0.3s ease;
}
</style>
