import revive_payload_client_4sVQNw7RlN from "/root/air-arts/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/root/air-arts/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/root/air-arts/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/root/air-arts/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/root/air-arts/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/root/air-arts/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/root/air-arts/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/root/air-arts/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/root/air-arts/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_Y6agwlnXZq from "/root/air-arts/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/root/air-arts/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_client_4F1kZh3YAB from "/root/air-arts/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import analytics_client_mZYnNRQBH8 from "/root/air-arts/plugins/analytics.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  plugin_client_4F1kZh3YAB,
  analytics_client_mZYnNRQBH8
]