<script setup>
const imageItem = [
    {
        image: '/article-with-button-image1.webp',
        text: 'Канальный кондиционер',
    },
    {
        image: '/article-with-button-image2.webp',
        text: 'Круглый воздуховод вентиляции',
    },
    {
        image: '/article-with-button-image3.webp',
        text: 'Проект вентиляции',
    },
    {
        image: '/article-with-button-image4.webp',
        text: 'Вытяжная решетка вентиляции',
    },
];
const emits = defineEmits(['openPopUpApplication']);

const handlerOpenPopUpApplication = () => {
    emits('openPopUpApplication');
};
</script>

<template>
    <section
        id="comfort"
        class="comfort-medium"
    >
        <div class="comfort-medium__content-wrapper">
            <div class="comfort-medium__content">
                <ArticleBlockWithButtonMedium
                    class="comfort-medium__article"
                    @open-pop-up-application="handlerOpenPopUpApplication"
                />
                <div class="comfort-medium__image-wrapper">
                    <div
                        v-for="(item, index) in imageItem"
                        :key="item.image"
                        class="comfort__image-block"
                        itemscope
                        itemtype="https://schema.org/ImageObject"
                    >
                        <span
                            itemprop="license"
                            content="https://www.freepikcompany.com/legal?_gl=1*55dupz*_ga*MTc4MjczODIwNS4xNzEwODAxNzg5*_ga_QWX66025LC*MTcxMDgwMTc4OS4xLjAuMTcxMDgwMTc4OS42MC4wLjA.*_ga_18B6QPTJPC*MTcxMDgwMTc4OS4xLjEuMTcxMDgwMjA0NC42MC4wLjA.#nav-freepik-license"
                        />
                        <div
                            itemprop="creator"
                            itemtype="https://schema.org/Organization"
                            itemscope
                        >
                            <span
                                itemprop="name"
                                content="https://www.freepikcompany.com/"
                            />
                            <span
                                itemprop="telephone"
                                content="+1-800-555-0123"
                            />
                            <div
                                class="schema-org"
                                itemprop="address"
                                itemscope
                                itemtype="https://schema.org/PostalAddress"
                            >
                                <span itemprop="streetAddress">Company, S.L. All rights reserved.</span>,
                            </div>
                        </div>
                        <span
                            itemprop="acquireLicensePage"
                            content="https://www.freepikcompany.com/legal?_gl=1*55dupz*_ga*MTc4MjczODIwNS4xNzEwODAxNzg5*_ga_QWX66025LC*MTcxMDgwMTc4OS4xLjAuMTcxMDgwMTc4OS42MC4wLjA.*_ga_18B6QPTJPC*MTcxMDgwMTc4OS4xLjEuMTcxMDgwMjA0NC42MC4wLjA.#nav-freepik-license"
                        />
                        <span
                            itemprop="name"
                            :content="item.text"
                        />
                        <span
                            itemprop="description"
                            :content="item.text"
                        />
                        <NuxtImg
                            :key="item.image"
                            itemprop="contentUrl"
                            :src="item.image"
                            :alt="item.text"
                            :title="`Изображение ${item.text}`"
                            class="comfort-medium__image"
                            :class="[`comfort-medium__image_${index}`]"
                            :width="index === 0 ? 235 : index === 1 ? 235 : index === 2 ? 235 : 235"
                            :height="index === 0 ? 375 : index === 1 ? 350 : index === 2 ? 385 : 294"
                            loading="lazy"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="less">
.comfort-medium {
    height: 890px;
    padding-top: 80px;
    background-color: #ffffff;

    &__content {
        width: 944px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: center right / 718px 718px no-repeat
            url('~/assets/img/comfort-block-background.svg');

        &-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__image {
        &_1 {
            padding-top: 68px;
        }

        &_3 {
            margin-top: -22px;
        }

        &-wrapper {
            width: 475px;
            height: 760px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}
</style>
