<script setup>
const schemaItem = [
    {
        title: 'Получение Заявки',
        description:
            'Заполните форму, укажите свои данные и отправьте нашим менеджерам в обработку',
    },
    {
        title: 'Консультация',
        description:
            'После обработки Вашей заявки, мы связываемся с Вами, выявляем потребности и пожелания, а также проводим техническую консультацию',
    },
    {
        title: 'Выезд На Объект',
        description:
            'В назначенное время мы выезжаем на объект и согласовываем основные технические решения, обсуждаем техническое задание',
    },
    {
        title: 'Составляем График',
        description:
            'На этом этапе мы составляем коммерческое предложение и занимаемся согласованием графика работ (проектирования, закупки оборудования и установки).',
    },
    {
        title: 'Договор',
        description:
            'Мы заключаем с Вами договор и начинаем проектирование и согласование проекта',
    },
    {
        title: 'Поставка И Установка',
        description:
            'На этом этапе, происходит поставка всех материалов и начинается выполнение установки',
    },
    {
        title: 'Подписание Акта Выполненных Работ',
        description: '',
    },
];
</script>

<template>
    <section
        id="schema"
        class="schema-medium"
    >
        <div class="schema-medium__content-wrapper">
            <div class="schema-medium__content">
                <h6 class="schema-medium__title">
                    Понятная и эффективная схема работы
                </h6>
                <div
                    v-for="(item, index) in schemaItem"
                    :key="item.title"
                    class="schema-medium__item"
                    :class="[`schema-medium__item_${index}`]"
                >
                    <div
                        class="schema-medium__item-title"
                        :class="[`schema-medium__item-title_${index}`]"
                    >
                        {{ item.title }}
                    </div>
                    <div class="schema-medium__item-description">
                        {{ item.description }}
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="less">
.schema-medium {
    height: 1090px;
    background-color: #ffffff;

    &__title {
        grid-area: ~'1/1/2/22';
        font-family: 'Ruberoid';
        font-size: 40px;
        font-weight: 500;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(73, 94, 76, 1);
    }

    &__content {
        width: 944px;
        height: 100%;
        display: grid;
        grid-template-rows: 60px 30px repeat(6, 126px) 145px;
        grid-template-columns: repeat(11, 0.78fr 0.2fr) 0.567fr;
        background:
            top 36px left 213px / 238px 232px no-repeat
                url('~/assets/img/schema-block-back1.webp'),
            top 176px left 151px / 527px 78px no-repeat
                url('~/assets/img/schema-block-line1.svg'),
            top 154px right 0px / 279px 246px no-repeat
                url('~/assets/img/schema-block-back2.webp'),
            top 356px right 189.71px / 506px 80px no-repeat
                url('~/assets/img/schema-block-line2.svg'),
            top 327px left 270px / 94px 120px no-repeat
                url('~/assets/img/schema-block-place.svg'),
            top 348px left 15px / 275px 160px no-repeat
                url('~/assets/img/schema-block-back3.svg'),
            top 511px left 241px / 219px 95px no-repeat
                url('~/assets/img/schema-block-line3.svg'),
            top 483px right 235px / 249px 274px no-repeat
                url('~/assets/img/schema-block-back4.webp'),
            top 666px right 303px / 335px 139px no-repeat
                url('~/assets/img/schema-block-line4.svg'),
            top 564px left 128px / 234px 252px no-repeat
                url('~/assets/img/schema-block-back5.svg'),
            top 748px left 268px / 457px 165px no-repeat
                url('~/assets/img/schema-block-line5.svg'),
            top 743px right 223px / 238px 189px no-repeat
                url('~/assets/img/schema-block-back6.webp'),
            top 937px center / 402px 99px no-repeat
                url('~/assets/img/schema-block-back7.svg'),
            #ffffff;

        &-wrapper {
            width: 100%;
            height: 1037px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__item {
        &_0 {
            grid-area: ~'3/1/4/10';
            width: 365px;
        }

        &_1 {
            grid-area: ~'4/13/5/24';
            width: 430px;
            margin-left: 23px;

            .schema-medium__item-title {
                text-align: right;
            }

            .schema-medium__item-description {
                text-align: right;
            }
        }

        &_2 {
            grid-area: ~'5/1/6/11';
            width: 405px;
        }

        &_3 {
            grid-area: ~'6/11/7/24';
            width: 490px;
            margin-left: 39px;

            .schema-medium__item-title {
                text-align: right;
            }

            .schema-medium__item-description {
                text-align: right;
            }
        }

        &_4 {
            grid-area: ~'7/1/8/10';
            width: 365px;
        }

        &_5 {
            grid-area: ~'8/12/9/24';
            width: 460px;
            margin-left: 8px;

            .schema-medium__item-title {
                text-align: right;
            }

            .schema-medium__item-description {
                text-align: right;
            }
        }

        &_6 {
            grid-area: ~'9/5/10/21';
            width: 636px;
            align-self: flex-end;
            position: relative;
            right: 4px;
        }

        &-title {
            font-family: 'Ruberoid';
            font-size: 35px;
            font-weight: 400;
            line-height: 47px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(120, 192, 129, 1);

            &_6 {
                &.schema-medium__item-title {
                    font-size: 30px;
                }
            }
        }

        &-description {
            font-family: 'Proxima Nova Rg';
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(73, 94, 76, 1);
        }
    }
}
</style>
