<script setup>
import { useIMask } from 'vue-imask';
import { ref, watch, nextTick } from '#imports';

const { el, mask, masked, unmasked } = useIMask({
    mask: '+{7} (000) 000-00-00',
});
const name = ref('');
const phone = ref('');
const buttonDisabled = ref(true);

watch([unmasked, name], () => {
    phone.value = masked.value;

    if (
        mask.value.masked.isComplete
        && name.value.length
        && unmasked.value.length === 11
    ) {
        buttonDisabled.value = false;
    }
    else buttonDisabled.value = true;
});

const sendTelegramMessage = async () => {
    if (buttonDisabled.value) return;

    try {
        await $fetch('/api/telegram', {
            method: 'POST',
            body: {
                name: name.value,
                phone: phone.value,
            },
        });
    }
    catch (error) {
        console.log(error);
    }
    finally {
        await nextTick();
        name.value = '';
        masked.value = '';
    }
};
</script>

<template>
    <form
        method="POST"
        action="../send.php"
        class="form-medium"
        autocomplete="off"
        enctype="multipart/form-data"
    >
        <label
            for="nameFormMedium"
            class="form-medium__label"
        >name</label>
        <input
            id="nameFormMedium"
            v-model="name"
            type="text"
            class="form-medium__name"
            placeholder="Ваше Имя"
        >
        <label
            for="phoneFormMedium"
            class="form-medium__label"
        >phone</label>
        <input
            id="phoneFormMedium"
            ref="el"
            type="text"
            class="form-medium__phone"
            placeholder="Номер телефона"
        >
        <ButtonComponent
            class="form-medium__button"
            text="отправить"
            :disabled="buttonDisabled"
            @click="sendTelegramMessage"
        />
    </form>
</template>

<style lang="less">
.form-medium {
    width: 984px;
    display: grid;
    align-items: center;
    grid-template-rows: min-content;
    grid-template-columns: repeat(11, 0.78fr 0.2fr) 0.567fr;
    margin-top: 16px;

    &__label {
        font-size: 0;
    }

    &__name {
        width: 290px;
        grid-area: ~'1/1/2/7';
    }

    &__phone {
        width: 290px;
        margin-left: 30px;
        grid-area: ~'1/7/2/15';
    }

    &__button {
        grid-area: ~'1/20/2/21';
        width: 229px;
        cursor: pointer;
        margin-left: 33px;

        &.button {
            border-radius: 20px;
            background-color: rgba(120, 192, 129, 0.25);

            .button__wrapper-content {
                width: 209px;
            }

            &.button_disabled {
                cursor: default;

                .button__wrapper-content {
                    background-color: #9cd3a3;
                }
            }
        }

    }

    input {
        height: 57px;
        border: none;
        outline: none;
        border-radius: 15px;
        padding: 20px 0 20px 20px;
        box-shadow: 0px 0px 12px 0px #495d4c26;

        &::placeholder {
            opacity: 0.5;
        }
    }
}
</style>
