<script setup>
import { useIMask } from 'vue-imask';
import { ref, watch, nextTick } from '#imports';

const { el, mask, masked, unmasked } = useIMask({
    mask: '+{7} (000) 000-00-00',
});
const name = ref('');
const phone = ref('');
const buttonDisabled = ref(true);

watch([unmasked, name], () => {
    phone.value = masked.value;

    if (
        mask.value.masked.isComplete
        && name.value.length
        && unmasked.value.length === 11
    ) {
        buttonDisabled.value = false;
    }
    else buttonDisabled.value = true;
});

const sendTelegramMessage = async () => {
    if (buttonDisabled.value) return;

    try {
        await $fetch('/api/telegram', {
            method: 'POST',
            body: {
                name: name.value,
                phone: phone.value,
            },
        });
    }
    catch (error) {
        console.log(error);
    }
    finally {
        await nextTick();
        name.value = '';
        masked.value = '';
    }
};
</script>

<template>
    <div class="footer-form-medium">
        <h2 class="footer-form-medium__title">
            Заполнить заявку
        </h2>
        <label
            for="nameFooterMedium"
            class="footer-form-medium__label"
        >name</label>
        <input
            id="nameFooterMedium"
            v-model="name"
            type="text"
            class="footer-form-medium__name"
            placeholder="Ваше Имя"
        >
        <label
            for="phoneFooterMedium"
            class="footer-form-medium__label"
        >phone</label>
        <input
            id="phoneFooterMedium"
            ref="el"
            type="text"
            class="footer-form-medium__phone"
            placeholder="Номер телефона"
        >
        <ButtonComponent
            class="footer-form-medium__button"
            text="отправить"
            :disabled="buttonDisabled"
            @click="sendTelegramMessage"
        />
    </div>
</template>

<style lang="less">
.footer-form-medium {
    width: 230px;
    display: flex;
    flex-direction: column;
    margin-top: 80px;

    &__title {
        font-family: 'Ruberoid';
        font-size: 26px;
        font-weight: 500;
        line-height: 35px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(73, 94, 76, 1);
        margin-bottom: 41px;
    }

    &__label {
        font-size: 0;
    }

    &__name {
        width: 230px;
        margin-bottom: 20px;
    }

    &__phone {
        width: 230px;
        margin-bottom: 45px;
    }

    &__button {
        width: 229px;
        cursor: pointer;

        &.button {
            border-radius: 20px;
            background-color: rgba(120, 192, 129, 0.25);

            .button__wrapper-content {
                width: 209px;
            }

            &.button_disabled {
                cursor: default;

                .button__wrapper-content {
                    background-color: #9cd3a3;
                }
            }
        }

    }

    input {
        height: 57px;
        border: none;
        outline: none;
        border-radius: 15px;
        padding: 20px 0 20px 20px;
        box-shadow: 0px 0px 10px 0px rgba(73, 93, 76, 0.1);

        &::placeholder {
            opacity: 0.5;
        }
    }
}
</style>
