import { useNuxtApp, useState } from '#imports';

export const useTemplatePage = () => {
    const { $viewport } = useNuxtApp();

    const templatePage = useState('templatePage');

    if ($viewport.breakpoint.value === 'desktopWide')
        templatePage.value = 'Desktop';
    else if (
        $viewport.breakpoint.value === 'desktop'
        || $viewport.breakpoint.value === 'desktopMedium'
    )
        templatePage.value = 'Medium';
    else if ($viewport.breakpoint.value === 'tablet')
        templatePage.value = 'Tablet';
    else templatePage.value = 'Mobile';

    return useState('templatePage');
};
