<script setup>
import { computed, ref } from '#imports';

const modeVent = ref(true);

const serviceItemVent = ref([
    {
        icon: '/icon-project.svg',
        description: 'Проектирование систем вентиляции любой сложности',
        title: 'Цены проектирования систем вентиляции',
    },
    {
        icon: '/icon-montag.svg',
        description: 'Установка приточной вентиляции',
        title: 'Ремонт приточной вентиляции',
    },
    {
        icon: '/icon-montag2.svg',
        description: 'Установка вытяжной вентиляции',
        title: 'Ремонт вытяжной вентиляции',
    },
    {
        icon: '/icon-montag3.svg',
        description: 'Установка вентиляции с увлажнением воздуха',
        title: 'Ремонт вентиляции с увлажнением воздуха',
    },
    {
        icon: '/icon-montag4.svg',
        description: 'Установка вентиляции с рекуператором',
        title: 'Ремонт вентиляции с рекуператором',
    },
    {
        icon: '/icon-montag5.svg',
        description: 'Установка вентиляции с осушением для бассейна',
        title: 'Ремонт вентиляции с осушением для бассейна',
    },
    {
        icon: '/icon-montag4.svg',
        description: 'Ремонт вентиляции',
        title: 'Цены ремонта вентиляции',
    },
    {
        icon: '/icon-montag5.svg',
        description: 'Обслуживание вентиляции',
        title: 'Цены обслуживания вентиляции',
    },
]);

const serviceItemCondey = ref([
    {
        icon: '/icon-project.svg',
        description: 'Проектирование кондиционеров',
        title: 'Цены проектирования кондиционеров',
    },
    {
        icon: '/icon-montag6.svg',
        description: 'Установка кондиционера',
        title: 'Продажа кондиционеров',
    },
    {
        icon: '/icon-montag7.svg',
        description: 'Установка мульти-сплит систем',
        title: 'Продажа мульти-сплит систем',
    },
    {
        icon: '/icon-montag8.svg',
        description: 'Установка VRV и VRF систем',
        title: 'Продажа VRV и VRF систем',
    },
    {
        icon: '/icon-montag10.svg',
        description: 'Установка настенных, канальных и кассетных кондиционеров',
        title: 'Продажа настенных, канальных и кассетных кондиционеров',
    },
    {
        icon: '/icon-montag9.svg',
        description: 'Ремонт кондиционеров',
        title: 'Ремонт кондиционеров всех типов',
    },
    {
        icon: '/icon-montag7.svg',
        description: 'Обслуживание кондиционеров',
        title: 'Обслуживание кондиционеров всех типов',
    },
]);

const iconButtonVent = computed(() => {
    return modeVent.value
        ? '/button-icon-vent-white.svg'
        : '/button-icon-vent-green.svg';
});

const iconButtonCondey = computed(() => {
    return modeVent.value
        ? '/button-icon-condey-green.svg'
        : '/button-icon-condey-white.svg';
});

const handlerModeVent = () => {
    if (modeVent.value) return;

    modeVent.value = true;
};

const handlerModeCondey = () => {
    if (!modeVent.value) return;

    modeVent.value = false;
};
</script>

<template>
    <div class="service-description-medium">
        <Transition>
            <div
                v-if="modeVent"
                class="service-description-medium__content-wrapper"
                itemscope
                itemtype="https://schema.org/ImageObject"
            >
                <span
                    itemprop="license"
                    content="https://www.freepikcompany.com/legal?_gl=1*55dupz*_ga*MTc4MjczODIwNS4xNzEwODAxNzg5*_ga_QWX66025LC*MTcxMDgwMTc4OS4xLjAuMTcxMDgwMTc4OS42MC4wLjA.*_ga_18B6QPTJPC*MTcxMDgwMTc4OS4xLjEuMTcxMDgwMjA0NC42MC4wLjA.#nav-freepik-license"
                />
                <div
                    itemprop="creator"
                    itemtype="https://schema.org/Organization"
                    itemscope
                >
                    <span
                        itemprop="name"
                        content="https://www.freepikcompany.com/"
                    />
                    <span
                        itemprop="telephone"
                        content="+1-800-555-0123"
                    />
                    <div
                        class="schema-org"
                        itemprop="address"
                        itemscope
                        itemtype="https://schema.org/PostalAddress"
                    >
                        <span itemprop="streetAddress">Company, S.L. All rights reserved.</span>,
                    </div>
                </div>
                <span
                    itemprop="acquireLicensePage"
                    content="https://www.freepikcompany.com/legal?_gl=1*55dupz*_ga*MTc4MjczODIwNS4xNzEwODAxNzg5*_ga_QWX66025LC*MTcxMDgwMTc4OS4xLjAuMTcxMDgwMTc4OS42MC4wLjA.*_ga_18B6QPTJPC*MTcxMDgwMTc4OS4xLjEuMTcxMDgwMjA0NC42MC4wLjA.#nav-freepik-license"
                />
                <span
                    itemprop="name"
                    content="Кондиционирование"
                />
                <span
                    itemprop="description"
                    content="Кондиционирование"
                />
                <div class="service-description-medium__item-wrapper">
                    <div
                        v-for="item in serviceItemCondey"
                        :key="item.icon"
                        class="service-description-medium__item"
                    >
                        <NuxtImg
                            :src="item.icon"
                            :alt="item.description"
                            :title="item.title"
                            class="service-description-medium__item-icon"
                            width="34"
                            height="34"
                            loading="lazy"
                        />
                        <p class="service-description-medium__item-paragraph">
                            {{ item.description }}
                        </p>
                    </div>
                </div>
                <NuxtImg
                    src="/Rectangle4303.webp"
                    itemprop="contentUrl"
                    alt="Продажа кондиционеров"
                    title="Установка кондиционеров"
                    class="service-description-medium__img"
                    width="204"
                    height="535"
                    loading="lazy"
                />
            </div>
            <div
                v-else
                class="service-description-medium__content-wrapper"
                itemscope
                itemtype="https://schema.org/ImageObject"
            >
                <span
                    itemprop="license"
                    content="https://www.freepikcompany.com/legal?_gl=1*55dupz*_ga*MTc4MjczODIwNS4xNzEwODAxNzg5*_ga_QWX66025LC*MTcxMDgwMTc4OS4xLjAuMTcxMDgwMTc4OS42MC4wLjA.*_ga_18B6QPTJPC*MTcxMDgwMTc4OS4xLjEuMTcxMDgwMjA0NC42MC4wLjA.#nav-freepik-license"
                />
                <div
                    itemprop="creator"
                    itemtype="https://schema.org/Organization"
                    itemscope
                >
                    <span
                        itemprop="name"
                        content="https://www.freepikcompany.com/"
                    />
                    <span
                        itemprop="telephone"
                        content="+1-800-555-0123"
                    />
                    <div
                        class="schema-org"
                        itemprop="address"
                        itemscope
                        itemtype="https://schema.org/PostalAddress"
                    >
                        <span itemprop="streetAddress">Company, S.L. All rights reserved.</span>,
                    </div>
                </div>
                <span
                    itemprop="acquireLicensePage"
                    content="https://www.freepikcompany.com/legal?_gl=1*55dupz*_ga*MTc4MjczODIwNS4xNzEwODAxNzg5*_ga_QWX66025LC*MTcxMDgwMTc4OS4xLjAuMTcxMDgwMTc4OS42MC4wLjA.*_ga_18B6QPTJPC*MTcxMDgwMTc4OS4xLjEuMTcxMDgwMjA0NC42MC4wLjA.#nav-freepik-license"
                />
                <span
                    itemprop="name"
                    content="Вентиляция"
                />
                <span
                    itemprop="description"
                    content="Вентиляция"
                />
                <NuxtImg
                    src="/Rectangle4285.webp"
                    itemprop="contentUrl"
                    alt="Услуги вентиляции"
                    title="Установка вентиляции"
                    class="service-description-medium__img"
                    width="204"
                    height="535"
                    loading="lazy"
                />
                <div class="service-description-medium__item-wrapper">
                    <div
                        v-for="item in serviceItemVent"
                        :key="item.icon"
                        class="service-description-medium__item"
                    >
                        <NuxtImg
                            :src="item.icon"
                            :alt="item.description"
                            :title="item.title"
                            class="service-description-medium__item-icon"
                            width="34"
                            height="34"
                            loading="lazy"
                        />
                        <p class="service-description-medium__item-paragraph">
                            {{ item.description }}
                        </p>
                    </div>
                </div>
            </div>
        </Transition>
        <ButtonComponent
            class="service-description-medium__button service-description-medium__button_vent"
            :class="{ 'service-description-medium__button_active': !modeVent }"
            :image="iconButtonCondey"
            text="Система Вентиляции"
            width="28px"
            height="28px"
            @click="handlerModeCondey"
        />
        <ButtonComponent
            class="service-description-medium__button service-description-medium__button_condey"
            :class="{ 'service-description-medium__button_active': modeVent }"
            :image="iconButtonVent"
            text="Кондиционирование"
            width="27px"
            height="21px"
            @click="handlerModeVent"
        />
    </div>
</template>

<style lang="less">
.service-description-medium {
    width: 475px;
    height: 515px;
    position: relative;
    background-color: #d9fadd;
    border-top-left-radius: 55px;
    border-bottom-left-radius: 55px;

    &__content-wrapper {
        height: 100%;
    }

    &__img {
        position: absolute;
        bottom: 0;
        right: 0;
        object-fit: contain;
        object-position: 13px 13px;
    }

    &__item {
        display: flex;
        align-items: flex-start;
        max-width: 356px;
        margin-bottom: 0;

        &-wrapper {
            width: 53%;
            height: 92%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 42px;
            margin-left: 20px;
        }

        &-icon {
            margin-right: 15px;
        }

        &-paragraph {
            font-family: 'Proxima Nova Rg';
            font-size: 14px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(73, 93, 76, 1);
            padding-top: 3px;
        }
    }

    .button.service-description-medium__button {
        width: 50px;
        height: 208px;
        position: absolute;
        justify-content: flex-end;
        background-color: rgba(217, 250, 221, 1);

        &:hover {
            .button__text {
                color: #54e165;
                transition: color 0.3s ease;
            }

            .button__img {
                filter: brightness(0) saturate(100%) invert(81%) sepia(16%)
                    saturate(1775%) hue-rotate(68deg) brightness(95%)
                    contrast(86%);
                transition: filter 0.3s ease;
            }
        }

        .button__wrapper-content {
            width: 45px;
            height: 208px;
            border-radius: 0;
        }

        &_vent {
            top: 50px;
            left: -50px;
            border-radius: 0;
            border-top-left-radius: 30px;
            padding: 10px 0 0 0;

            .button {
                &__wrapper-content {
                    box-shadow: unset;
                    background-color: #ffffff;
                    border-top-left-radius: 25px;
                    transition: background-color 1s ease;
                }

                &__text {
                    font-family: 'Proxima Nova Rg';
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 0em;
                    text-align: left;
                    writing-mode: vertical-lr;
                    text-transform: unset;
                    transform: rotate(180deg);
                    color: rgba(124, 162, 129, 1);
                    transition: color 1s ease;
                }

                &__img {
                    margin-top: 8px;
                    margin-right: 2px;
                }
            }
        }

        &_condey {
            bottom: 50px;
            left: -50px;
            border-radius: 0;
            border-bottom-left-radius: 30px;
            padding: 0 0 10px 0;

            .button {
                &__wrapper-content {
                    box-shadow: unset;
                    background-color: #ffffff;
                    border-bottom-left-radius: 25px;
                    transition: background-color 1s ease;
                }

                &__text {
                    font-family: 'Proxima Nova Rg';
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 0em;
                    text-align: left;
                    writing-mode: vertical-lr;
                    text-transform: unset;
                    transform: rotate(180deg);
                    color: rgba(124, 162, 129, 1);
                    transition: color 1s ease;
                }

                &__img {
                    margin-top: 8px;
                    margin-right: 2px;
                }
            }
        }

        &_active {
            &.service-description-medium__button {
                &:hover {
                    .button__text {
                        color: #ffffff;
                        transition: color 0.3s ease;
                    }

                    .button__img {
                        filter: none;
                    }
                }
            }

            .button {
                &__wrapper-content {
                    background-color: #5ccd6a;
                    transition: background-color 1s ease;
                }

                &__text {
                    color: #ffffff;
                    transition: color 1s ease;
                }
            }
        }
    }

    .v-enter-active,
    .v-leave-active {
        opacity: 1;
        transition: opacity 0.3s ease 0.3s;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
        transition: opacity 0.3s ease;
    }
}
</style>
