<script setup>
const emits = defineEmits(['openPopUpApplication']);

const handlerOpenPopUpApplication = () => {
    emits('openPopUpApplication');
};
</script>

<template>
    <section
        id="main-page"
        class="main-page-medium"
    >
        <div class="main-page-medium__content-wrapper">
            <div class="main-page-medium__content">
                <div class="main-page-medium__background" />
                <MainDescriptionMedium
                    class="main-page-medium__description"
                    @open-pop-up-application="handlerOpenPopUpApplication"
                />
            </div>
        </div>
    </section>
</template>

<style lang="less">
.main-page-medium {
    &__content {
        position: relative;
        width: 944px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 50px;
        }
    }
}
</style>
