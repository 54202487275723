<script setup>
const iconItem = [
    {
        icon: '/article-with-button-air.svg',
        description: 'Чистый воздух',
    },
    {
        icon: '/article-with-button-temp.svg',
        description: 'Контроль температуры воздуха',
    },
    {
        icon: '/article-with-button-house.svg',
        description: 'Природная свежесть воздуха',
    },
    {
        icon: '/article-with-button-bacteri.svg',
        description: 'Удаление неприятных запахов',
    },
    {
        icon: '/article-with-button-huminidi.svg',
        description: 'Поддержавние оптимальной влажности воздуха',
    },
    {
        icon: '/article-with-button-pollution.svg',
        description: 'Удаление углекислого газа',
    },
];
const paragraphItem = [
    {
        description: 'Создание комфортных условий для работы и отдыха',
    },
    {
        description: 'Чистый, свежий, приточный воздух круглый год',
    },
    {
        description:
            'Удаление неприятных запахов, избыточной влажности и накопившегося углекислого газа из помещений',
    },
    {
        description:
            'Поддержание комфортной температуры и влажности воздуха зимой и летом',
    },
];

const emits = defineEmits(['openPopUpApplication']);

const openPopUpApplication = () => {
    emits('openPopUpApplication');
};
</script>

<template>
    <div class="article-with-button-medium">
        <h6 class="article-with-button-medium__title">
            Комфорт И Уют В Любое Время Года
        </h6>
        <div class="article-with-button-medium__icon-container">
            <div
                v-for="(item, index) in iconItem"
                :key="item.icon"
                class="article-with-button-medium__icon-wrapper"
            >
                <NuxtImg
                    :src="item.icon"
                    :alt="`${item.description}`"
                    :title="`Изображение ${item.description}`"
                    class="article-with-button-medium__icon"
                    :class="[`article-with-button-medium__icon_${index}`]"
                    :width="index === 0 ? 32 : index === 1 ? 16 : index === 2 ? 28 : index === 3 ? 28 : index === 4 ? 22 : 28"
                    :height="index === 0 ? 22 : index === 1 ? 28 : index === 2 ? 28 : index === 3 ? 28 : index === 4 ? 28 : 24"
                    loading="lazy"
                />
            </div>
        </div>
        <div
            v-for="item in paragraphItem"
            :key="item.description"
            class="article-with-button-medium__description-item-wrapper"
        >
            <div class="article-with-button-medium__description-item-icon">
                <div
                    class="article-with-button-medium__description-item-icon2"
                />
            </div>
            <p class="article-with-button-medium__description-item">
                {{ item.description }}
            </p>
        </div>
        <p class="article-with-button-medium__paragraph">
            <span class="article-with-button-medium__paragraph_company">Air Arts </span>поможет улучшить ваше самочувствие и повысить работоспособность
            днем, а ночью подарит здоровый и крепкий сон. Это важно для тех, кто
            следит за своим здоровьем и здоровьем своих близких.
        </p>
        <ButtonComponent
            class="article-with-button-medium__button"
            text="Заполнить заявку"
            @click="openPopUpApplication"
        />
    </div>
</template>

<style lang="less">
.article-with-button-medium {
    width: 388px;
    height: 620px;
    position: relative;
    margin-top: 30px;

    &__title {
        font-family: 'Ruberoid';
        font-size: 40px;
        font-weight: 500;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(73, 94, 76, 1);
        margin-bottom: 18px;
    }

    &__icon {
        &-container {
            display: flex;
            margin-bottom: 27px;
        }

        &-wrapper {
            width: 51.2px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            border: 1px solid #78c081;
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__description {
        &-item {
            font-family: 'Proxima Nova Rg';
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: #7ca381;

            &-wrapper {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                margin-bottom: 12px;
            }

            &-icon {
                width: 12px;
                height: 12px;
                padding: 1.5px;
                margin-right: 10px;
                border-radius: 50%;
                border: 1px solid #78c081;
                margin-top: 3.5px;
            }

            &-icon2 {
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: #78c081;
            }
        }
    }

    &__paragraph {
        font-family: 'Proxima Nova Rg';
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(132, 132, 132, 1);
        padding-top: 6px;
        margin-bottom: 25px;

        &_company {
            font-weight: 600;
        }
    }

    &__button {
        &.button {
            width: 388px;
            height: 75px;
            border-radius: 20px;
            background-color: rgba(120, 192, 129, 0.25);

            .button__wrapper-content {
                width: 366px;
                height: 60px;
                border-radius: 15px;

                .button__text {
                    font-family: 'Ruberoid';
                    font-size: 17px;
                    font-weight: 600;
                    line-height: 23px;
                    letter-spacing: 0.04em;
                    text-align: left;
                }
            }
        }
    }
}
</style>
