<script setup>
import { useHead } from '#imports';

useHead({
    titleTemplate: (titleChunk) => {
        return titleChunk
            ? `${titleChunk}`
            : 'Кондиционеры и вентиляция Продажа Установка Ремонт Обслуживание';
    },
});
</script>

<template>
    <div
        class="schema-org"
        itemscope
        itemtype="https://schema.org/HVACBusiness"
    >
        <div itemprop="telephone">
            +7 (495) 143-02-02
        </div>
        <div>
            <div itemprop="name">
                Кондиционеры и вентиляция Установка Ремонт Обслуживание | Air Arts
            </div>
            <meta
                itemprop="description"
                content="Профессиональная установка, ремонт и обслуживание кондиционеров и вентиляции в Щёлково и Москве. Купите кондиционер или вентиляционное оборудование у нас."
            >
        </div>
        <div
            itemprop="contactPoint"
            itemscope
            itemtype="https://schema.org/ContactPoint"
        >
            <div itemprop="contactType">
                Обслуживание клиентов
            </div>
            <div itemprop="telephone">
                +7 (495) 143-02-02
            </div>
            <div itemprop="email">
                info@air-arts.ru
            </div>
            <div itemprop="areaServed">
                Щёлково, Московская область, Россия
            </div>
            <div itemprop="availableLanguage">
                Russian
            </div>
        </div>
        <div
            itemprop="address"
            itemscope
            itemtype="https://schema.org/PostalAddress"
        >
            <div>Наши адресс</div>
            <span itemprop="streetAddress">Беляева 7-А</span>,
            <span itemprop="addressLocality">Щёлково</span>,
            <span itemprop="addressRegion">Московская область</span>,
            <span itemprop="postalCode">141104</span>,
            <span itemprop="addressCountry">RU</span>
        </div>
        <div
            itemprop="contactPoint"
            itemscope
            itemtype="https://schema.org/ContactPoint"
        >
            <div itemprop="contactType">
                Обслуживание клиентов
            </div>
            <div itemprop="telephone">
                +7 (495) 143-02-02
            </div>
            <div itemprop="email">
                info@air-arts.ru
            </div>
            <div itemprop="areaServed">
                Щёлково, Московская область, Россия
            </div>
            <div itemprop="availableLanguage">
                Russian
            </div>
        </div>
        <a
            itemprop="sameAs"
            href="https://t.me/air_arts"
            rel="nofollow"
        >Telegram</a>
        <a
            itemprop="sameAs"
            href="https://vk.com/air__arts"
            rel="nofollow"
        >ВКонтакте</a>,
    </div>
    <div
        class="schema-org"
        itemscope
        itemtype="https://schema.org/ElectronicsStore"
    >
        <div itemprop="telephone">
            +7 (495) 143-02-02
        </div>
        <div>
            <div itemprop="name">
                Продажа кондиционеров
            </div>
            <meta
                itemprop="description"
                content="Продажа кондиционеров по лучшим ценам, рассрочка, бесплатная доставка, установка"
            >
        </div>
        <div
            itemprop="address"
            itemscope
            itemtype="https://schema.org/PostalAddress"
        >
            <div>Наши адресс</div>
            <span itemprop="streetAddress">Беляева 7-А</span>,
            <span itemprop="addressLocality">Щёлково</span>,
            <span itemprop="addressRegion">Московская область</span>,
            <span itemprop="postalCode">141104</span>,
            <span itemprop="addressCountry">RU</span>
        </div>
        <div
            itemprop="contactPoint"
            itemscope
            itemtype="https://schema.org/ContactPoint"
        >
            <div itemprop="contactType">
                Обслуживание клиентов
            </div>
            <div itemprop="telephone">
                +7 (495) 143-02-02
            </div>
            <div itemprop="email">
                info@air-arts.ru
            </div>
            <div itemprop="areaServed">
                Щёлково, Московская область, Россия
            </div>
            <div itemprop="availableLanguage">
                Russian
            </div>
        </div>
        <a
            itemprop="sameAs"
            href="https://t.me/air_arts"
            rel="nofollow"
        >Telegram</a>

        <a
            itemprop="sameAs"
            href="https://vk.com/air__arts"
            rel="nofollow"
        >ВКонтакте</a>,
    </div>
    <div
        class="schema-org"
        itemscope
        itemtype="https://schema.org/WebSite"
    >
        <div itemprop="name">
            Кондиционеры и вентиляция Продажа Установка Ремонт Обслуживание | Air Arts
        </div>
        <meta
            itemprop="description"
            content="Профессиональная установка, ремонт и обслуживание кондиционеров и вентиляции в Щёлково и Москве. Купите кондиционер или вентиляционное оборудование у нас!"
        >

        <a
            itemprop="sameAs"
            href="https://t.me/air_arts"
            rel="nofollow"
        >Telegram</a>
        <a
            itemprop="sameAs"
            href="https://vk.com/air__arts"
            rel="nofollow"
        >ВКонтакте</a>,
    </div>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<style lang="less"></style>
