<script setup>
import ImageUrl from '~/assets/img/main-img.webp?url';
import { useHead, useScroll, useTemplatePage, resolveComponent, computed, ref, navigateTo } from '#imports';

useHead({
    link: [
        { rel: 'preload', as: 'image', href: ImageUrl },
    ],
});

const scrollY = useScroll();
const templatePage = useTemplatePage();

const mainPageBackgroundStyle = {
    background: templatePage.value === 'Desktop'
        ? `top right / 800px 921px no-repeat url(${ImageUrl}),
        top center / 100% 928px
            linear-gradient(
                180deg,
                #f1f1f1 0%,
                #f0f2f1 16.25%,
                #ebedec 43.26%,
                #e6e8e7 72.5%,
                #dee2e1 100%
            )`
        : templatePage.value === 'Medium'
            ? `top right / 630px 742px no-repeat url(${ImageUrl}),
            top center / 100% 958px
                linear-gradient(
                    180deg,
                    #f3f1f1 0%,
                    #f4f0f0 16.25%,
                    #e8ebea 43.26%,
                    #dfe3e1 72.5%,
                    #e8eaea 100%
                )`
            : templatePage.value === 'Tablet'
                ? `top right / 490px 555px no-repeat url(${ImageUrl}),
        top center / 100% 940px
            linear-gradient(
                180deg,
                #f3f1f1 0%,
                #f1f1f1 16.25%,
                #e4e6e5 43.26%,
                #dce0de 72.5%,
                #dee2e1 100%
            )`
                : `linear-gradient(
        270deg,
        #f2f2f2 0.09%,
        #f0f2f1 17.41%,
        #f1f1f1 35.53%,
        #eaeaea 51.42%,
        #e6e9eb 66.94%,
        #e4e6e6 82.21%,
        #dee2e1 99.91%
    )`,
};

const AppHeader = resolveComponent(`AppHeader${templatePage.value}`);
const AppFooter = resolveComponent(`AppFooter${templatePage.value}`);
const PopUpConnection = resolveComponent(
    `PopUpConnection${templatePage.value}`,
);

const buttonUpActive = computed(() => {
    if (scrollY.value > 600) return true;
    else return false;
});

const popUpConnection = ref(false);

const handlerOpenPopUpConnection = () => {
    document.body.style.overflow = 'hidden';
    popUpConnection.value = true;
};

const handlerClosePopUpConnection = () => {
    document.body.style.overflow = 'unset';
    popUpConnection.value = false;
};

const backgroundPageTablet = computed(() => {
    return templatePage.value === 'Mobile' ? 'mobile' : '';
});

const handlerTo = (link) => {
    navigateTo(link);
};
</script>

<template>
    <div
        class="page"
        :class="[
            { 'page_active-popup': popUpConnection },
            backgroundPageTablet,
        ]"
        :style="mainPageBackgroundStyle"
    >
        <component
            :is="AppHeader"
            @open-pop-up-connection="handlerOpenPopUpConnection"
        />
        <main class="main">
            <slot />
        </main>
        <component :is="AppFooter" />
        <component
            :is="PopUpConnection"
            v-if="popUpConnection"
            @close-pop-up-connection="handlerClosePopUpConnection"
        />
        <div
            class="page__button-up"
            :class="{ 'page__button-up_active': buttonUpActive }"
            @click="handlerTo('#header')"
        >
            <div class="page__button-up-circle" />
        </div>
        <div class="page__button-phone">
            <div class="page__button-phone-circle">
                <a
                    class="page__button-phone-link"
                    href="tel:+74951430202"
                    aria-label="Телефон"
                >+7&nbsp;(495)&nbsp;143&#8209;02&#8209;02</a>
            </div>
        </div>
        <div class="page__button-whatsapp">
            <div class="page__button-whatsapp-circle">
                <a
                    class="page__button-whatsapp-link"
                    href="//wa.me/79999139089"
                    aria-label="WhatsApp"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Кондиционеры купить в Air Arts через WhatsApp
                </a>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.page {
    min-height: 100vh;

    &__button {
        &-up {
            cursor: pointer;
            position: fixed;
            bottom: 220px;
            right: 20px;
            width: 80px;
            height: 80px;
            z-index: 50;
            padding: 10px;
            opacity: 0;
            border-radius: 50%;
            pointer-events: none;
            transition: opacity 0.3s ease;
            background-color: rgba(255, 255, 255, 0.45);
            box-shadow: 2px -2px 12px 0px rgba(34, 111, 33, 0.36) inset;

            &:hover {
                .page__button-up-circle {
                    background-color: #54e165;
                    transition: background-color 0.3s ease;
                }
            }

            &-circle {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background:
                    center / 25px 25px no-repeat
                        url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDQ0NC44MTkgNDQ0LjgxOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDQ0LjgxOSA0NDQuODE5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTQzMy45NjgsMjc4LjY1N0wyNDguMzg3LDkyLjc5Yy03LjQxOS03LjA0NC0xNi4wOC0xMC41NjYtMjUuOTc3LTEwLjU2NmMtMTAuMDg4LDAtMTguNjUyLDMuNTIxLTI1LjY5NywxMC41NjYgICBMMTAuODQ4LDI3OC42NTdDMy42MTUsMjg1Ljg4NywwLDI5NC41NDksMCwzMDQuNjM3YzAsMTAuMjgsMy42MTksMTguODQzLDEwLjg0OCwyNS42OTNsMjEuNDExLDIxLjQxMyAgIGM2Ljg1NCw3LjIzLDE1LjQyLDEwLjg1MiwyNS42OTcsMTAuODUyYzEwLjI3OCwwLDE4Ljg0Mi0zLjYyMSwyNS42OTctMTAuODUyTDIyMi40MSwyMTMuMjcxTDM2MS4xNjgsMzUxLjc0ICAgYzYuODQ4LDcuMjI4LDE1LjQxMywxMC44NTIsMjUuNywxMC44NTJjMTAuMDgyLDAsMTguNzQ3LTMuNjI0LDI1Ljk3NS0xMC44NTJsMjEuNDA5LTIxLjQxMiAgIGM3LjA0My03LjA0MywxMC41NjctMTUuNjA4LDEwLjU2Ny0yNS42OTNDNDQ0LjgxOSwyOTQuNTQ1LDQ0MS4yMDUsMjg1Ljg4NCw0MzMuOTY4LDI3OC42NTd6IiBmaWxsPSIjRkZGRkZGIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==),
                    rgba(92, 205, 106, 1);
                box-shadow: 2px -2px 12px 0px rgba(34, 111, 33, 0.36) inset;
                transition: background-color 0.3s ease;
            }

            &_active {
                opacity: 1;
                pointer-events: auto;
                transition: opacity 0.3s ease;
            }
        }

        &-phone {
            cursor: pointer;
            position: fixed;
            bottom: 120px;
            right: 20px;
            width: 80px;
            height: 80px;
            z-index: 50;
            padding: 10px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.45);
            box-shadow: 2px -2px 12px 0px rgba(34, 111, 33, 0.36) inset;

            &:hover {
                .page__button-phone-circle {
                    background-color: #54e165;
                    transition: background-color 0.3s ease;
                }
            }

            &-circle {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background:
                    center / 25px 25px no-repeat url('~/assets/img/icon-phone-button.svg'),
                    rgba(92, 205, 106, 1);
                box-shadow: 2px -2px 12px 0px rgba(34, 111, 33, 0.36) inset;
                transition: background-color 0.3s ease;
            }

            &-link {
                width: 100%;
                height: 100%;
                font-size: 0;
            }
        }

        &-whatsapp {
            cursor: pointer;
            position: fixed;
            bottom: 20px;
            right: 20px;
            width: 80px;
            height: 80px;
            z-index: 50;
            padding: 10px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.45);
            box-shadow: 2px -2px 12px 0px rgba(34, 111, 33, 0.36) inset;

            &:hover {
                .page__button-whatsapp-circle {
                    background-color: #54e165;
                    transition: background-color 0.3s ease;
                }
            }

            &-circle {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background:
                    center / 35px 35px no-repeat url('~/assets/img/icons8-whatsapp.svg'),
                    rgba(92, 205, 106, 1);
                box-shadow: 2px -2px 12px 0px rgba(34, 111, 33, 0.36) inset;
                transition: background-color 0.3s ease;
            }

            &-link {
                width: 100%;
                height: 100%;
                font-size: 0;
            }
        }
    }

    &__hidden {
        &-links {
            position: absolute;
            left: -1000px;
            top: -1000px;
            visibility: hidden;
        }

        &-link {
            font-family: 'Proxima Nova Rg';
        }
    }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .page {
        &__button {
            &-up {
                bottom: 120px;
                right: 10px;
                width: 50px;
                height: 50px;
                padding: 5px;

                &-circle {
                    width: 40px;
                    height: 40px;
                    background:
                        center / 17px 17px no-repeat
                            url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDQ0NC44MTkgNDQ0LjgxOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDQ0LjgxOSA0NDQuODE5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTQzMy45NjgsMjc4LjY1N0wyNDguMzg3LDkyLjc5Yy03LjQxOS03LjA0NC0xNi4wOC0xMC41NjYtMjUuOTc3LTEwLjU2NmMtMTAuMDg4LDAtMTguNjUyLDMuNTIxLTI1LjY5NywxMC41NjYgICBMMTAuODQ4LDI3OC42NTdDMy42MTUsMjg1Ljg4NywwLDI5NC41NDksMCwzMDQuNjM3YzAsMTAuMjgsMy42MTksMTguODQzLDEwLjg0OCwyNS42OTNsMjEuNDExLDIxLjQxMyAgIGM2Ljg1NCw3LjIzLDE1LjQyLDEwLjg1MiwyNS42OTcsMTAuODUyYzEwLjI3OCwwLDE4Ljg0Mi0zLjYyMSwyNS42OTctMTAuODUyTDIyMi40MSwyMTMuMjcxTDM2MS4xNjgsMzUxLjc0ICAgYzYuODQ4LDcuMjI4LDE1LjQxMywxMC44NTIsMjUuNywxMC44NTJjMTAuMDgyLDAsMTguNzQ3LTMuNjI0LDI1Ljk3NS0xMC44NTJsMjEuNDA5LTIxLjQxMiAgIGM3LjA0My03LjA0MywxMC41NjctMTUuNjA4LDEwLjU2Ny0yNS42OTNDNDQ0LjgxOSwyOTQuNTQ1LDQ0MS4yMDUsMjg1Ljg4NCw0MzMuOTY4LDI3OC42NTd6IiBmaWxsPSIjRkZGRkZGIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==),
                        rgba(92, 205, 106, 1);
                }
            }

            &-phone {
                bottom: 65px;
                right: 10px;
                width: 50px;
                height: 50px;
                padding: 5px;

                &-circle {
                    width: 40px;
                    height: 40px;
                    background:
                        center / 17px 17px no-repeat
                            url('~/assets/img/icon-phone-button.svg'),
                        rgba(92, 205, 106, 1);
                }
            }

            &-whatsapp {
                bottom: 10px;
                right: 10px;
                width: 50px;
                height: 50px;
                padding: 5px;

                &-circle {
                    width: 40px;
                    height: 40px;
                    background:
                        center / 25px 25px no-repeat url('~/assets/img/icons8-whatsapp.svg'),
                        rgba(92, 205, 106, 1);
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .page {
        &.mobile {
            background: linear-gradient(
                270deg,
                #f2f2f2 0.09%,
                #f0f2f1 17.41%,
                #f1f1f1 35.53%,
                #eaeaea 51.42%,
                #e6e9eb 66.94%,
                #e4e6e6 82.21%,
                #dee2e1 99.91%
            );
        }
    }
}
</style>
