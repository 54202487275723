<script setup>
const emits = defineEmits(['openPopUpApplication']);

const openPopUpApplication = () => {
    emits('openPopUpApplication');
};
</script>

<template>
    <div class="main-description-medium">
        <h1 class="main-description-medium__title">
            Кондиционеры И Вентиляция С Air Arts
        </h1>
        <NuxtImg
            src="/Ellipse-3.svg"
            alt="Вспомогательное изображение"
            title="Иконка вспомогательного изображения"
            class="main-description-medium__circle"
            width="10"
            height="10"
            preload
        />
        <NuxtImg
            src="/Ellipse-4.svg"
            alt="Вспомогательное изображение"
            title="Иконка вспомогательного изображения"
            class="main-description-medium__circle main-description-medium__circle_second"
            width="10"
            height="10"
            preload
        />
        <NuxtImg
            src="/Ellipse-5.svg"
            alt="Вспомогательное изображение"
            title="Иконка вспомогательного изображения"
            class="main-description-medium__circle main-description-medium__circle_three"
            width="10"
            height="10"
            preload
        />
        <h2 class="main-description-medium__title-description">
            Проектирование, продажа, установка, ремонт и обслуживание
            кондиционеров и вентиляции
        </h2>
        <div class="main-description-medium__wrapper-contacts">
            <NuxtImg
                src="/icon-place.svg"
                alt="Адрес компании Air Arts"
                title="Иконка адреса"
                class="main-description-medium__icon-place"
                width="33"
                height="33"
                preload
            />
            <div class="main-description-medium__wrapper-address">
                <address class="main-description-medium__address">
                    <a
                        href="//yandex.ru/maps/10765/shelkovo/?ll=37.991528%2C55.919461&mode=routes&rtext=~55.877350%2C38.087820&rtt=auto&ruri=~&z=12.98"
                        aria-label="Адрес"
                        class="main-description-medium__phone-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >г. Щёлково, ул. Беляева 7а, Московская область
                    </a>
                </address>
            </div>
            <NuxtImg
                src="/icon-phone.svg"
                alt="Телефон компании Air Arts"
                title="Иконка телефона"
                class="main-description-medium__icon-phone"
                width="33"
                height="33"
                preload
            />
            <div class="main-description-medium__wrapper-phone">
                <address class="main-description-medium__phone">
                    <a
                        href="tel:+74951430202"
                        aria-label="Телефон"
                        class="main-description-medium__phone-link"
                    >+7&nbsp;(495)&nbsp;143&#8209;02&#8209;02
                    </a>
                </address>
            </div>
        </div>
        <div
            class="main-description-medium__wrapper-contacts main-description-medium__wrapper-contacts_second"
        >
            <NuxtImg
                src="/footer-block-mail.svg"
                alt="Email компании Air Arts"
                title="Иконка электронной почты"
                class="main-description-medium__icon-email"
                width="33"
                height="33"
                preload
            />
            <div class="main-description-medium__wrapper-email">
                <address class="main-description-medium__phone">
                    <a
                        href="mailto:info@air-arts.ru"
                        aria-label="Email"
                        class="main-description-medium__phone-link"
                    >email: info@air-arts.ru
                    </a>
                </address>
            </div>
            <NuxtImg
                src="/icons-whatsapp.svg"
                alt="WhatsApp компании Air Arts"
                title="Иконка вацапа"
                class="main-description-medium__icon-whatsapp"
                width="33"
                height="33"
                preload
            />
            <div class="main-description-medium__wrapper-whatsapp">
                <address class="main-description-medium__phone">
                    <a
                        href="//wa.me/79999139089"
                        aria-label="WhatsApp"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="main-description-medium__phone-link"
                    >WhatsApp
                    </a>
                </address>
            </div>
        </div>
        <ButtonComponent
            class="main-description-medium__button"
            text="Оставить заявку"
            @click="openPopUpApplication"
        />
    </div>
</template>

<style lang="less">
.main-description-medium {
    width: 715px;
    position: relative;
    margin-top: 58.5px;

    &__title {
        font-family: 'Ruberoid';
        font-size: 55px;
        font-weight: 600;
        line-height: 74px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(73, 94, 76, 1);

        &-description {
            width: 530px;
            font-family: 'Proxima Nova Rg';
            font-size: 18px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(124, 163, 129, 1);
            padding-left: 60px;
        }
    }

    &__circle {
        position: absolute;
        top: 158px;
        left: 0;

        &_second {
            left: 20px;
        }

        &_three {
            left: 40px;
        }
    }

    &__wrapper {
        &-contacts {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-right: 33px;
            margin-top: 30px;

            &_second {
                margin-top: 10px;
            }
        }

        &-email {
            width: 266px;
        }
    }

    &__icon {
        &-place,
        &-phone,
        &-whatsapp,
        &-email {
            margin-right: 20px;
        }
    }

    &__wrapper-address {
        margin-right: 25px;
    }

    &__address {
        width: 239px;
        font-style: normal;
        font-family: 'Proxima Nova Rg';
        font-size: 19px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(123, 137, 125, 1);
    }

    &__phone {
        font-style: normal;
        font-family: 'Proxima Nova Rg';
        font-size: 19px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(123, 137, 125, 1);

        &-link {
            text-decoration: none;
            color: rgba(123, 137, 125, 1);
        }
    }

    &__button.button {
        width: 350px;
        height: 75px;
        margin-top: 46px;
        margin-left: 40px;
        border-radius: 20px;
        background-color: rgba(120, 192, 129, 0.25);

        .button__wrapper-content {
            width: 330px;
            height: 55px;
            border-radius: 15px;

            &:hover {
                background: rgba(84, 225, 101, 1);
                box-shadow: 2px -2px 12px 0px rgba(34, 111, 33, 0.36) inset;
            }

            .button__text {
                font-family: 'Ruberoid';
                font-size: 17px;
                font-weight: 600;
                line-height: 23px;
                letter-spacing: 0.04em;
                text-align: left;
            }
        }
    }
}
</style>
