<script setup>
import { resolveComponent, useTemplatePage } from '#imports';

const templatePage = useTemplatePage();

const ApplicationForm = resolveComponent(
    `ApplicationForm${templatePage.value}`,
);
</script>

<template>
    <section class="application-form-medium">
        <div class="application-form-medium__content-wrapper">
            <div class="application-form-medium__content">
                <h6 class="application-form-medium__title">
                    Оставьте заявку и мы с вами свяжемся
                </h6>
                <div class="application-form-medium__form-wrapper">
                    <component :is="ApplicationForm" />
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="less">
.application-form-medium {
    height: 190px;
    background:
        center right 150px / 856px 213px no-repeat
            url('~/assets/img/application-form-block-background.svg'),
        right / 161px 182px no-repeat
            url('~/assets/img/application-form-block-background2.svg'),
        #d9fadd;

    &__content {
        width: 944px;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        &-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__title {
        font-family: 'Ruberoid';
        font-size: 40px;
        font-weight: 500;
        line-height: 45px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(73, 94, 76, 1);
    }
}
</style>
