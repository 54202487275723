
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index1uSYPFW4G4Meta } from "/root/air-arts/pages/about/index.vue?macro=true";
import { default as _91name_93ohH0bcGbAKMeta } from "/root/air-arts/pages/articles/[name].vue?macro=true";
import { default as indexymoi625pawMeta } from "/root/air-arts/pages/articles/index.vue?macro=true";
import { default as _91name_93y17x8wKmLhMeta } from "/root/air-arts/pages/catalog/[group]/[name].vue?macro=true";
import { default as indexv0JWi6fs4XMeta } from "/root/air-arts/pages/catalog/[group]/index.vue?macro=true";
import { default as indexS6oziPnYV5Meta } from "/root/air-arts/pages/catalog/index.vue?macro=true";
import { default as indexNUr50KtdrFMeta } from "/root/air-arts/pages/conditioning/air-conditioning-maintenance/index.vue?macro=true";
import { default as indexadwyzo8Zt8Meta } from "/root/air-arts/pages/conditioning/index.vue?macro=true";
import { default as indexWfiPggJx23Meta } from "/root/air-arts/pages/conditioning/installing-air-conditioner/index.vue?macro=true";
import { default as indexwwqTSOp91VMeta } from "/root/air-arts/pages/conditioning/repair-air-conditioner/index.vue?macro=true";
import { default as indexjeeF7ZxvemMeta } from "/root/air-arts/pages/contacts/index.vue?macro=true";
import { default as indexN1Bgju2QSNMeta } from "/root/air-arts/pages/index.vue?macro=true";
import { default as indexadXnGpmlZ9Meta } from "/root/air-arts/pages/ventilation/index.vue?macro=true";
import { default as index7hhxIcTpyPMeta } from "/root/air-arts/pages/ventilation/installation-ventilation/index.vue?macro=true";
import { default as indexMDyKS8gWSXMeta } from "/root/air-arts/pages/ventilation/repair-ventilation/index.vue?macro=true";
import { default as indexovZoTqgnEkMeta } from "/root/air-arts/pages/ventilation/ventilation-maintenance/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: index1uSYPFW4G4Meta || {},
    component: () => import("/root/air-arts/pages/about/index.vue")
  },
  {
    name: "articles-name",
    path: "/articles/:name()",
    meta: _91name_93ohH0bcGbAKMeta || {},
    component: () => import("/root/air-arts/pages/articles/[name].vue")
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexymoi625pawMeta || {},
    component: () => import("/root/air-arts/pages/articles/index.vue")
  },
  {
    name: "catalog-group-name",
    path: "/catalog/:group()/:name()",
    meta: _91name_93y17x8wKmLhMeta || {},
    component: () => import("/root/air-arts/pages/catalog/[group]/[name].vue")
  },
  {
    name: "catalog-group",
    path: "/catalog/:group()",
    meta: indexv0JWi6fs4XMeta || {},
    component: () => import("/root/air-arts/pages/catalog/[group]/index.vue")
  },
  {
    name: "catalog",
    path: "/catalog",
    meta: indexS6oziPnYV5Meta || {},
    component: () => import("/root/air-arts/pages/catalog/index.vue")
  },
  {
    name: "conditioning-air-conditioning-maintenance",
    path: "/conditioning/air-conditioning-maintenance",
    meta: indexNUr50KtdrFMeta || {},
    component: () => import("/root/air-arts/pages/conditioning/air-conditioning-maintenance/index.vue")
  },
  {
    name: "conditioning",
    path: "/conditioning",
    meta: indexadwyzo8Zt8Meta || {},
    component: () => import("/root/air-arts/pages/conditioning/index.vue")
  },
  {
    name: "conditioning-installing-air-conditioner",
    path: "/conditioning/installing-air-conditioner",
    meta: indexWfiPggJx23Meta || {},
    component: () => import("/root/air-arts/pages/conditioning/installing-air-conditioner/index.vue")
  },
  {
    name: "conditioning-repair-air-conditioner",
    path: "/conditioning/repair-air-conditioner",
    meta: indexwwqTSOp91VMeta || {},
    component: () => import("/root/air-arts/pages/conditioning/repair-air-conditioner/index.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    meta: indexjeeF7ZxvemMeta || {},
    component: () => import("/root/air-arts/pages/contacts/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/root/air-arts/pages/index.vue")
  },
  {
    name: "ventilation",
    path: "/ventilation",
    meta: indexadXnGpmlZ9Meta || {},
    component: () => import("/root/air-arts/pages/ventilation/index.vue")
  },
  {
    name: "ventilation-installation-ventilation",
    path: "/ventilation/installation-ventilation",
    meta: index7hhxIcTpyPMeta || {},
    component: () => import("/root/air-arts/pages/ventilation/installation-ventilation/index.vue")
  },
  {
    name: "ventilation-repair-ventilation",
    path: "/ventilation/repair-ventilation",
    meta: indexMDyKS8gWSXMeta || {},
    component: () => import("/root/air-arts/pages/ventilation/repair-ventilation/index.vue")
  },
  {
    name: "ventilation-ventilation-maintenance",
    path: "/ventilation/ventilation-maintenance",
    meta: indexovZoTqgnEkMeta || {},
    component: () => import("/root/air-arts/pages/ventilation/ventilation-maintenance/index.vue")
  }
]