<script setup>
import { useHead, useTemplatePage, resolveComponent, ref, useRuntimeConfig } from '#imports';

const config = useRuntimeConfig();

const templatePage = useTemplatePage();

useHead({
    meta: [
        {
            name: 'description',
            content: (() => {
                return 'Профессиональная установка, ремонт и обслуживание кондиционеров и вентиляции в Щёлково и Москве. Купите кондиционер или вентиляционное оборудование у нас!'.slice(
                    0,
                    159,
                );
            })(),
        },
        { property: 'og:url', content: config.public.baseURL },
    ],
    script: [
        {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
                '@context': 'https://schema.org/',
                '@type': 'Organization',
                'name': 'Air Arts',
                'alternateName': 'Воздушное искусство',
                'legalName': 'ООО Форклифтпарт Партнёр',
                'description':
                    'Профессиональная установка, ремонт и обслуживание кондиционеров и вентиляции в Щёлково и Москве. Купите кондиционер или вентиляционное оборудование у нас!',
                'url': 'https://air-arts.ru',
                '@id': 'https://air-arts.ru',
                'image': 'https://air-arts.ru/mainImage.webp',
                'logo': {
                    '@type': 'ImageObject',
                    '@id': 'https://air-arts.ru/#logo',
                    'url': 'https://air-arts.ru/android-chrome-192x192.webp',
                    'caption': 'Air Arts',
                },
                'telephone': '+7 (495) 143-02-02',
                'email': 'info@air-arts.ru',
                'contactPoint': [
                    {
                        '@type': 'ContactPoint',
                        'telephone': '+7 (495) 143-02-02',
                        'email': 'info@air-arts.ru',
                        'contactType': 'Обслуживание клиентов',
                        'areaServed': 'RU',
                        'availableLanguage': 'Russian',
                    },
                ],
                'address': {
                    '@type': 'PostalAddress',
                    'streetAddress':
                        'Беляева 7-А',
                    'addressLocality': 'Щёлково',
                    'addressRegion': 'Московская область',
                    'postalCode': '141104',
                    'addressCountry': 'RU',
                },
                'sameAs': [
                    'https://t.me/air_arts',
                    'https://vk.com/air__arts',
                ],
            }),
        },
        {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'HVACBusiness',
                'name': ' Проектирование, продажа, установка, ремонт и обслуживание кондиционеров и вентиляции | Air Arts',
                'url': 'https://air-arts.ru',
                'description': 'Профессиональная установка, ремонт и обслуживание кондиционеров и вентиляции в Щёлково и Москве. Купите кондиционер или вентиляционное оборудование у нас.',
                'address': {
                    '@type': 'PostalAddress',
                    'streetAddress': 'Беляева 7-А',
                    'addressLocality': 'Щёлково',
                    'addressRegion': 'Московская область',
                    'postalCode': '141104',
                    'addressCountry': 'RU',
                },
                'contactPoint': {
                    '@type': 'ContactPoint',
                    'contactType': 'Обслуживание клиентов',
                    'telephone': '+7 (495) 143-02-02',
                    'email': 'info@air-arts.ru',
                    'areaServed': 'Щёлково, Московская область, Россия',
                    'availableLanguage': 'Russian',
                },
                'sameAs': [
                    'https://t.me/air_arts',
                    'https://vk.com/air__arts',
                ],
            }),
        },
        {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'ProfessionalService',
                'additionalType':
                    'https://en.wikipedia.org/wiki/Heating,_ventilation,_and_air_conditioning',
                'name': 'Air Arts',
                'image': 'https://air-arts.ru/mainImage.webp',
                '@id': 'https://air-arts.ru',
                'url': 'https://air-arts.ru',
                'telephone': '+7 (495) 143-02-02',
                'priceRange': '800-15000',
                'department': {
                    '@type': 'Organization',
                    'name': 'Air Arts',
                    'url': 'https://air-arts.ru',
                },
                'review': [
                    {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': '5',
                            'bestRating': '5',
                        },
                        'author': {
                            '@type': 'Person',
                            'name': 'Любовь',
                        },
                    },
                    {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': '5',
                            'bestRating': '5',
                        },
                        'author': {
                            '@type': 'Person',
                            'name': 'Галина А.',
                        },
                    },
                    {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': '5',
                            'bestRating': '5',
                        },
                        'author': {
                            '@type': 'Person',
                            'name': 'Наталья Г.',
                        },
                    },
                    {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': '5',
                            'bestRating': '5',
                        },
                        'author': {
                            '@type': 'Person',
                            'name': 'Елена Лахова',
                        },
                    },
                    {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': '5',
                            'bestRating': '5',
                        },
                        'author': {
                            '@type': 'Person',
                            'name': 'наталья т.',
                        },
                    },
                    {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': '5',
                            'bestRating': '5',
                        },
                        'author': {
                            '@type': 'Person',
                            'name': 'Ника Сурманидзе',
                        },
                    },
                    {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': '5',
                            'bestRating': '5',
                        },
                        'author': {
                            '@type': 'Person',
                            'name': 'Инкогнито 3530',
                        },
                    },
                    {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': '5',
                            'bestRating': '5',
                        },
                        'author': {
                            '@type': 'Person',
                            'name': 'Кирилл К.',
                        },
                    },
                    {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': '5',
                            'bestRating': '5',
                        },
                        'author': {
                            '@type': 'Person',
                            'name': 'Александр Анатольевич',
                        },
                    },
                    {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': '5',
                            'bestRating': '5',
                        },
                        'author': {
                            '@type': 'Person',
                            'name': 'GARRY SIDEY',
                        },
                    },
                    {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': '5',
                            'bestRating': '5',
                        },
                        'author': {
                            '@type': 'Person',
                            'name': 'Сергей Боров',
                        },
                    },
                    {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': '5',
                            'bestRating': '5',
                        },
                        'author': {
                            '@type': 'Person',
                            'name': 'Денис Б.',
                        },
                    },
                    {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': '5',
                            'bestRating': '5',
                        },
                        'author': {
                            '@type': 'Person',
                            'name': 'Алёна К.',
                        },
                    },
                    {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': '5',
                            'bestRating': '5',
                        },
                        'author': {
                            '@type': 'Person',
                            'name': 'Наталья Седова',
                        },
                    },
                    {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': '5',
                            'bestRating': '5',
                        },
                        'author': {
                            '@type': 'Person',
                            'name': 'Елена Е.',
                        },
                    },
                    {
                        '@type': 'Review',
                        'reviewRating': {
                            '@type': 'Rating',
                            'ratingValue': '5',
                            'bestRating': '5',
                        },
                        'author': {
                            '@type': 'Person',
                            'name': 'Елена Коршунова',
                        },
                    },
                ],
                'aggregateRating': {
                    '@type': 'AggregateRating',
                    'ratingValue': '4.8',
                    'bestRating': '5',
                    'ratingCount': '16',
                },
                'address': {
                    '@type': 'PostalAddress',
                    'streetAddress':
                        'Беляева 7-А',
                    'addressLocality': 'Щёлково',
                    'addressRegion': 'Московская область',
                    'postalCode': '141104',
                    'addressCountry': 'RU',
                },
                'geo': {
                    '@type': 'GeoCoordinates',
                    'latitude': '55.923982',
                    'longitude': '38.000907',
                },
                'openingHoursSpecification': [
                    {
                        '@type': 'OpeningHoursSpecification',
                        'dayOfWeek': [
                            'Monday',
                            'Tuesday',
                            'Wednesday',
                            'Thursday',
                            'Friday',
                        ],
                        'opens': '9:00',
                        'closes': '18:00',
                    },
                ],
                'sameAs': [
                    'https://t.me/air_arts',
                    'https://vk.com/air__arts',
                ],
            }),
        },
        {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'WebSite',
                '@id': 'https://air-arts.ru',
                'name': 'Кондиционеры и вентиляция Продажа Установка Ремонт Обслуживание | Air Arts',
                'description':
                    'Профессиональная установка, ремонт и обслуживание кондиционеров и вентиляции в Щёлково и Москве. Купите кондиционер или вентиляционное оборудование у нас!',
                'url': 'https://air-arts.ru',
            }),
        },
        {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'WebPage',
                'name': 'Главная',
                'description':
                    'Профессиональная установка, ремонт и обслуживание кондиционеров и вентиляции в Щёлково и Москве. Купите кондиционер или вентиляционное оборудование у нас!',
                'url': 'https://air-arts.ru',
                '@id': 'https://air-arts.ru',
                'lastReviewed': '2025-01-02T17:42:19.509Z',
                'mainContentOfPage': {
                    '@type': 'WebPageElement',
                    'cssSelector': '.main',
                    'isAccessibleForFree': true,
                },
                'primaryImageOfPage': {
                    '@type': 'ImageObject',
                    '@id': 'https://air-arts.ru/mainImage.webp',
                    'url': 'https://air-arts.ru/mainImage.webp',
                    'caption': 'Air Arts',
                },
                'mainEntity': {
                    '@type': 'Service',
                    'brand': {
                        '@type': 'Brand',
                        'name': 'Dahatsu',
                    },
                    'areaServed': 'Щёлково, Московская область, Россия',
                    'description':
                        'Профессиональная установка, ремонт и обслуживание кондиционеров и вентиляции в Щёлково и Москве. Купите кондиционер или вентиляционное оборудование у нас!',
                    'additionalType':
                        'https://en.wikipedia.org/wiki/Heating,_ventilation,_and_air_conditioning',
                    'image': 'https://air-arts.ru/mainImage.webp',
                    'logo': {
                        '@type': 'ImageObject',
                        '@id': 'https://air-arts.ru/#logo',
                        'url': 'https://air-arts.ru/android-chrome-192x192.webp',
                        'caption': 'Air Arts',
                    },
                    'name': 'Air Arts',
                    'provider': {
                        '@type': 'Organization',
                        'name': 'Air Arts',
                        'url': 'https://air-arts.ru',
                    },
                    'url': 'https://air-arts.ru',
                },
            }),
        },
        {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'ItemList',
                'itemListElement': [
                    {
                        '@type': 'ListItem',
                        'position': 1,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Главная',
                            'url': 'https://air-arts.ru',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 2,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Кондиционирование',
                            'url': 'https://air-arts.ru/conditioning',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 3,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Установка кондиционеров',
                            'url': 'https://air-arts.ru/conditioning/installing-air-conditioner',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 4,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Ремонт кондиционеров',
                            'url': 'https://air-arts.ru/conditioning/repair-air-conditioner',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 5,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Обслуживание кондиционеров',
                            'url': 'https://air-arts.ru/conditioning/air-conditioning-maintenance',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 6,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Вентиляция',
                            'url': 'https://air-arts.ru/ventilation',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 7,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Установка вентиляции',
                            'url': 'https://air-arts.ru/ventilation/installation-ventilation',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 8,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Ремонт вентиляции',
                            'url': 'https://air-arts.ru/ventilation/repair-ventilation',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 9,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Обслуживание вентиляции',
                            'url': 'https://air-arts.ru/ventilation/ventilation-maintenance',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 10,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Каталог кондиционеров',
                            'url': 'https://air-arts.ru/catalog',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 11,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Кондиционеры настенные (сплит системы)',
                            'url': 'https://air-arts.ru/catalog/split-system',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 12,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Кондиционеры настенные (мульти-сплит системы)',
                            'url': 'https://air-arts.ru/catalog/multi-split-system',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 13,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Кондиционеры кассетные',
                            'url': 'https://air-arts.ru/catalog/kassetnie',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 14,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Кондиционеры напольно-потолочные',
                            'url': 'https://air-arts.ru/catalog/napolno-potolochnie',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 15,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Кондиционеры колонные',
                            'url': 'https://air-arts.ru/catalog/kolonnie',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 16,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Кондиционеры канальные',
                            'url': 'https://air-arts.ru/catalog/kanalnie',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 17,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Статьи',
                            'url': 'https://air-arts.ru/articles',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 18,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'О нас',
                            'url': 'https://air-arts.ru/about',
                        },
                    },
                    {
                        '@type': 'ListItem',
                        'position': 19,
                        'item': {
                            '@type': 'SiteNavigationElement',
                            'name': 'Контакты',
                            'url': 'https://air-arts.ru/contacts',
                        },
                    },
                ],
            }),
        },
    ],
    __dangerouslyDisableSanitizers: ['script'],
});

const SectionMain = resolveComponent(`SectionMain${templatePage.value}`);
const SectionServices = resolveComponent(
    `SectionServices${templatePage.value}`,
);
const SectionSolution = resolveComponent(
    `SectionSolution${templatePage.value}`,
);
const SectionStep = resolveComponent(`SectionStep${templatePage.value}`);
const SectionApplicationForm = resolveComponent(
    `SectionApplicationForm${templatePage.value}`,
);
const SectionComfort = resolveComponent(`SectionComfort${templatePage.value}`);
const SectionSchema = resolveComponent(`SectionSchema${templatePage.value}`);
const SectionPortfolio = resolveComponent(
    `SectionPortfolio${templatePage.value}`,
);
const PopUpApplication = resolveComponent(
    `PopUpApplication${templatePage.value}`,
);

const popUpApplication = ref(false);

const handlerOpenPopUpApplication = () => {
    document.body.style.overflow = 'hidden';
    popUpApplication.value = true;
};

const handlerClosePopUpApplication = () => {
    document.body.style.overflow = 'unset';
    popUpApplication.value = false;
};
</script>

<template>
    <div class="components-wrapper">
        <div
            class="schema-org"
            itemscope
            itemtype="https://schema.org/Organization"
        >
            <div itemprop="telephone">
                +7 (495) 143-02-02
            </div>
            <div itemprop="name">
                Кондиционеры и вентиляция Продажа Установка Ремонт Обслуживание
            </div>

            <div itemprop="description">
                Профессиональная установка, ремонт и обслуживание кондиционеров и вентиляции в Щёлково и Москве. Купите кондиционер или вентиляционное оборудование у нас!
            </div>

            <div
                itemprop="logo"
                itemscope
                itemtype="https://schema.org/ImageObject"
            >
                <img
                    src="https://air-arts.ru/logo.svg"
                    alt="Логотип компании Air Arts"
                    title="Logo Air Arts"
                    itemprop="image"
                >
            </div>

            <div
                itemprop="url"
                rel="noopener noreferrer"
                href="`https://air-arts.ru`"
            />

            <div
                itemprop="address"
                itemscope
                itemtype="https://schema.org/PostalAddress"
            >
                <span itemprop="streetAddress">Беляева 7-А</span>,
                <span itemprop="addressLocality">Щёлково</span>,
                <span itemprop="addressRegion">Московская область</span>,
                <span itemprop="postalCode">141104</span>,
                <span itemprop="addressCountry">RU</span>
            </div>

            <div
                itemprop="contactPoint"
                itemscope
                itemtype="https://schema.org/ContactPoint"
            >
                <div itemprop="contactType">
                    Обслуживание клиентов
                </div>
                <div itemprop="telephone">
                    +7 (495) 143-02-02
                </div>
                <div itemprop="email">
                    info@air-arts.ru
                </div>
                <div itemprop="areaServed">
                    Щёлково, Московская область, Россия
                </div>
                <div itemprop="availableLanguage">
                    Russian
                </div>
            </div>
            <div
                itemprop="sameAs"
                href="https://t.me/air_arts"
            >
                <a
                    href="https://t.me/air_arts"
                    rel="nofollow"
                >
                    Telegram
                </a>
            </div>
            <div
                itemprop="sameAs"
                href="https://vk.com/air__arts"
            >
                <a
                    href="https://vk.com/air__arts"
                    rel="nofollow"
                >
                    ВКонта́кте
                </a>
            </div>

            <div
                itemprop="founder"
                itemscope
                itemtype="https://schema.org/Person"
            >
                <span itemprop="name">Денис Бахарев</span>
                <meta
                    itemprop="birthDate"
                    content="2005-04-29T18:31:42"
                >
                <meta
                    itemprop="jobTitle"
                    content="Development"
                >
            </div>

            <div
                itemprop="foundingDate"
                content="2020-10-03"
            >
                <time datetime="2020-10-03">March 10, 2020</time>
            </div>

            <div
                itemprop="hasPOS"
                itemscope
                itemtype="https://schema.org/Place"
            >
                <div itemprop="telephone">
                    +7 (495) 143-02-02
                </div>
                <span itemprop="name">Air Arts</span>
                <div
                    itemprop="address"
                    itemscope
                    itemtype="https://schema.org/PostalAddress"
                >
                    <span itemprop="streetAddress">Беляева 7-А</span>,
                    <span itemprop="addressLocality">Щёлково</span>,
                    <span itemprop="addressRegion">Московская область</span>,
                    <span itemprop="postalCode">141104</span>,
                    <span itemprop="addressCountry">RU</span>
                </div>
            </div>
            <div itemprop="areaServed">
                Щёлково, Московская область, Россия
            </div>
            <div
                itemprop="additionalType"
                href="https://air-arts.ru"
                content="https://air-arts.ru"
            >
                <meta
                    itemprop="name"
                    content="Air Arts"
                >
            </div>
        </div>
        <div
            class="schema-org"
            itemscope
            itemtype="https://schema.org/ProfessionalService"
        >
            <div itemprop="telephone">
                +7 (495) 143-02-02
            </div>
            <div itemprop="name">
                Проектирование, продажа, установка, ремонт и обслуживание кондиционеров и вентиляции.
            </div>
            <div itemprop="description">
                Профессиональная установка, ремонт и обслуживание кондиционеров и вентиляции в Щёлково и Москве. Купите кондиционер или вентиляционное оборудование у нас!
            </div>
            <div
                itemprop="logo"
                itemscope
                itemtype="https://schema.org/ImageObject"
            >
                <img
                    src="https://air-arts.ru/logo.svg"
                    alt="Логотип компании Air Arts"
                    title="Logo Air Arts"
                    itemprop="image"
                >
            </div>
            <div
                itemprop="url"
                rel="noopener noreferrer"
                href="`https://air-arts.ru`"
            />
            <div
                itemprop="address"
                itemscope
                itemtype="https://schema.org/PostalAddress"
            >
                <span itemprop="streetAddress">Беляева 7-А</span>,
                <span itemprop="addressLocality">Щёлково</span>,
                <span itemprop="addressRegion">Московская область</span>,
                <span itemprop="postalCode">141104</span>,
                <span itemprop="addressCountry">RU</span>
            </div>
            <div
                itemprop="contactPoint"
                itemscope
                itemtype="https://schema.org/ContactPoint"
            >
                <div itemprop="contactType">
                    Обслуживание клиентов
                </div>
                <div itemprop="telephone">
                    +7 (495) 143-02-02
                </div>
                <div itemprop="email">
                    info@air-arts.ru
                </div>
                <div itemprop="areaServed">
                    Щёлково, Московская область, Россия
                </div>
                <div itemprop="availableLanguage">
                    Russian
                </div>
            </div>
            <div
                itemprop="sameAs"
                href="https://t.me/air_arts"
            >
                <a
                    content="https://t.me/air_arts"
                    rel="nofollow"
                >
                    Telegram
                </a>
            </div>
            <div
                itemprop="sameAs"
                href="https://vk.com/air__arts"
            >
                <a
                    content="https://vk.com/air__arts"
                    rel="nofollow"
                >
                    ВКонта́кте
                </a>
            </div>
            <div
                itemprop="founder"
                itemscope
                itemtype="https://schema.org/Person"
            >
                <span itemprop="name">Денис Бахарев</span>
                <meta
                    itemprop="birthDate"
                    content="2005-04-29T18:31:42"
                >
                <meta
                    itemprop="jobTitle"
                    content="Development"
                >
            </div>

            <div
                itemprop="foundingDate"
                content="2020-10-03"
            >
                <time datetime="2020-10-03">March 10, 2020</time>
            </div>
            <div itemprop="areaServed">
                Щёлково, Московская область, Россия
            </div>
        </div>
        <component
            :is="SectionMain"
            @open-pop-up-application="handlerOpenPopUpApplication"
        />
        <component :is="SectionServices" />
        <component :is="SectionSolution" />
        <component :is="SectionStep" />
        <component :is="SectionApplicationForm" />
        <component
            :is="SectionComfort"
            @open-pop-up-application="handlerOpenPopUpApplication"
        />
        <component :is="SectionSchema" />
        <component :is="SectionPortfolio" />
        <component
            :is="PopUpApplication"
            v-if="popUpApplication"
            @close-pop-up-application="handlerClosePopUpApplication"
        />
    </div>
</template>

<style lang="less">
.main {
    &__title {
        width: 1170px;
        font-family: 'Ruberoid';
        font-size: 64px;
        font-weight: 600;
        line-height: 74px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(73, 94, 76, 1);
        margin: 50px auto 20px;

        &-second {
            font-family: 'Ruberoid';
            font-size: 64px;
            font-weight: 600;
            line-height: 74px;
            letter-spacing: 0em;
            position: relative;
            color: rgba(73, 94, 76, 1);
        }

        &-three {
            font-family: 'Ruberoid';
            font-size: 64px;
            font-weight: 600;
            line-height: 74px;
            letter-spacing: 0.03em;
            position: relative;
            color: rgba(120, 192, 129, 1);
        }

        &-four {
            font-family: 'Ruberoid';
            font-size: 64px;
            font-weight: 600;
            line-height: 74px;
            letter-spacing: 0em;
            position: relative;
            color: rgba(73, 94, 76, 1);
        }

        &-five {
            font-family: 'Ruberoid';
            font-size: 64px;
            font-weight: 600;
            line-height: 74px;
            letter-spacing: 0em;
            position: relative;
            color: rgba(73, 94, 76, 1);
            left: 390px;
        }
    }
}
</style>
