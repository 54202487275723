<script setup></script>

<template>
    <div class="article-medium">
        <h3 class="article-medium__title">
            Предоставляемые Услуги
        </h3>
        <div class="article-medium__line" />
        <p class="article-medium__paragraph article-medium__paragraph_one">
            Добро пожаловать на сайт Air Arts! Мы специализируемся на
            продаже, установке и обслуживании кондиционеров и систем
            вентиляции, предлагая надежные решения для создания
            идеального климата в вашем доме или офисе. Узнайте,
            как наши услуги помогут вам обеспечить комфорт и свежесть
            в любое время года.При разработке проекта предложим
            техническое решение оптимальное по соотношению цены и
            качества, оно идеально впишется в ваш дизайн, а также
            будет учитывать все ваши пожелания.На нашем сайте вы
            найдете все необходимые решения для поддержания
            оптимального климата в помещениях. Мы предлагаем широкий
            ассортимент кондиционеров и вентиляционных систем от
            ведущих производителей. Наша команда профессионалов
            поможет вам выбрать подходящее оборудование, учитывая
            ваши пожелания и бюджет. Мы предлагаем следующие услуги:
            Консультации по выбору кондиционеров и вентиляционных систем.
            Монтаж и установку оборудования с соблюдением всех норм и
            стандартов. Обслуживание и ремонт систем кондиционирования
            и вентиляции.
        </p>
        <p class="article-medium__paragraph article-medium__paragraph_second">
            <strong>Установка кондиционера</strong> выполняется опытными
            бригадами с соблюдением всех действующих технических норм, правил
            безопасности и строго по согласованному проекту.
        </p>
        <p class="article-medium__paragraph article-medium__paragraph_three">
            Одинаково эффективно выполняем проектирование и установку
            кондиционера на объектах любой сложности – от небольшой квартиры, до
            объектов со сложными технологическими процессами на основе
            действующей нормативной документации.
        </p>
    </div>
</template>

<style lang="less">
.article-medium {
    max-width: 396px;
    position: relative;

    &__title {
        font-family: 'Ruberoid';
        font-size: 40px;
        font-weight: 500;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(73, 94, 76, 1);
        margin-bottom: 15px;
    }

    &__line {
        width: 220px;
        height: 3px;
        background-color: rgba(120, 192, 129, 1);
        position: absolute;
        top: 87px;
        right: 0;
        border-radius: 1.5px;
    }

    &__paragraph {
        font-family: 'Proxima Nova Rg';
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(132, 132, 132, 1);

        &_one {
            margin-bottom: 15px;
        }

        &_second {
            margin-bottom: 15px;
        }
    }
}
</style>
